import React, { useState } from "react";
import './style.scss';
import arrowMore from 'src/Assets/Images/Blog/ArrowMore.svg';
import { useNavigate } from "react-router-dom";

export default function BlogTopics({topics}) {
    const navigate = useNavigate()
    const [pageCount, setPageCount] = useState(8)

    function TopicItem({item}) {
        const showTopicBlogs = () => navigate(`/blog/${item.name.replace(/\s+/g, '-')}/${item.id}`)
        
        return (
            <div className="topic-item" onClick={() => showTopicBlogs()}>
                {/* <div className="icon-wrapper">
                    <img src={item.icon}/>
                </div> */}
                <div className="name">{item.name}</div>
                <img src={arrowMore}/>
            </div>
        )
    }

    return (
        <div className="blog-topics">
            <div className="container">
                <div className="title">MORE TOPICS</div>
                <div className="row">
                    {
                        topics.slice(0, pageCount)
                            .map((item, index) => (
                                <div className="col-lg-3" key={index}>
                                    <TopicItem item={item}/>
                                </div>
                            ))
                        }
                </div>
                {
                    pageCount <= topics.length && (
                        <div className="d-flex j-c mt-4">
                            <div className="btn btn-outline-primary" onClick={() => setPageCount(pageCount + 8)}>Load more</div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}