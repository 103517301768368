import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/VmsHome/HomeImg.svg';

export default function VMentalHealthHomeIntro({onGetStarted}) {

    return (
        <div className="vmr-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-6 left-section">
                                    <h1>Virtual Mental Health Assistant</h1>
                                    <h2>Our Virtual Mental Health Assistant Will Elevate Your Practice</h2>
                                    <p>Tailored virtual assistance to empower your practice in delivering exceptional mental healthcare.</p>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Hire a Mental Health Virtual Assistant</div>
                                </div>
                                <div className="right-sec col-lg-6">
                                    <img src={homeImg} alt="mental health virtual assistant, mental health assistant"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}