import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import check from 'src/Assets/Images/VmrHome/cardCheck.svg';

export default function Benefits({benefits, subTitle}) {

    

    function BenefitCard({item, index}) {
        return (
            <div className={`b-card ${item.color}`}>
                <div className="title-wrapper">
                    <div className="title">{item.title}</div>
                </div>
                <div className="message">{item.message}</div>
                <div className="num-bg"></div>
                <img src={check} className="check"/>
                <div className="num">{index < 9 ? `0${index+1}` : `${index+1}`}</div>
            </div>
        )
    }

    return (
        <div className="vr-benefits">
            <div className="container">
                <h2>Benefits</h2>
                { subTitle && <h3>{subTitle}</h3>}
            </div>
            <div className="container slider-container">
                    <div className="benefits-slider">
                        <Media
                            queries={{
                                lg: "(min-width: 1655px)",
                                md: "(min-width: 1360px) and (max-width: 1654px)",
                                sm: "(min-width: 1032px) and (max-width: 1359px)",
                                normal: "(max-width: 1031px)",
                            }}
                        >
                            {(matches) => (
                                <OwlCarousel 
                                    className="owl-theme"
                                    stagePadding={
                                        matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                                    }
                                    margin={
                                        matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                                    }
                                    items={matches.lg ? 4 : matches.md ? 3 : matches.sm ? 2 : 1}
                                    dots
                                    loop
                                    autoplay
                                    rewind
                                    autoplaySpeed={500}
                                    autoplayHoverPause
                                    autoplayTimeout={2000}
                                >
                                    {
                                        benefits.map((item, index) => (
                                            <BenefitCard item={item} key={index} index={index}/>
                                        ))
                                    }
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                </div>
        </div>
    )
}