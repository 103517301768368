import React from "react";
import './style.scss';
import { Modal } from "react-bootstrap";

export default function VideoModal({show, onHide}) {

    return  (
        <Modal className="video-modal modal-lg" show={show} onHide={onHide}>
            <video width="100%" height="100%"
                key={1}
                src="https://cdn-na.muse.ai/u/Bhkfhyt/b47a65b9351e38e6eb86780b09f3e9f9b8e9d795f1af5111f2f34a7f31f9e157/videos/hls.m3u8?v=23:1708607666"
                controls>
                </video>
        </Modal>
    )
}