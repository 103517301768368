import React from "react";
import './style.scss'
import Partner1 from 'src/Assets/Images/Home/Partner1.png';
import Partner2 from 'src/Assets/Images/Home/Partner2.png';
import Partner3 from 'src/Assets/Images/Home/Partner3.png';
import Partner4 from 'src/Assets/Images/Home/Partner4.png';
import Partner5 from 'src/Assets/Images/Home/Partner5.png';
import Partner6 from 'src/Assets/Images/Home/Partner6.png';

export default function Partners() {

    return (
        <div className="partners">
            <div className="container">
                <div className="content">
                    <h2>Our Partners</h2>
                    <div className="p-list">
                        <div className="partner-item">
                            <img src={Partner1}/>
                        </div>
                        <div className="partner-item">
                            <img src={Partner2}/>
                        </div>
                        <div className="partner-item">
                            <img src={Partner3}/>
                        </div>
                        <div className="partner-item">
                            <img src={Partner4}/>
                        </div>
                        <div className="partner-item">
                            <img src={Partner5}/>
                        </div>
                        <div className="partner-item">
                            <img src={Partner6}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}