import React from "react";
import './style.scss';
import { ArticleItem, BlogTopics, Stories } from "src/Components";
import BlogFeatured from "./BlogFeatured";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import headImg from 'src/Assets/Images/Blog/BlogHImg.svg';
import _ from 'lodash';
import { Helmet } from "react-helmet";

export default function BlogPage() {
    const navigate = useNavigate();

    const {
        posts,
        topics,
    } = useSelector(state => state.Home)


    const groupedPosts = (items) => {
        const group = Object.values(items.reduce((acc, item) => {
            acc[item.category.name] = (acc[item.category.name] || []).concat(item);
            return acc;
        }, {}));
        return group
    }

    function ArticleSection({items}) {

        const showTopicBlogs = () => {
            if (items.length === 0) return
            const topic = items[0].category;
            navigate(`/blog/${topic.name.replace(/\s+/g, '-')}/${topic.id}`)
        }

        return (
            <div className={`article-section`}>
                <div className="content-wrapper">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-3 left-section">
                                <div className="title">{items.length > 0 && items[0].category.name}</div>
                                <div className="btn btn-outline-primary" onClick={() => showTopicBlogs()}>View all articles</div>
                            </div>
                            <div className="col-lg-9 right-section">
                                <div className="row">
                                    {
                                        items.slice(0, 3)
                                            .map((item, index) => (
                                                <div className="col-lg-4" key={index}>
                                                    <ArticleItem item={item}/>
                                                </div>
                                            ))
                                    }
                                </div>
                                <div className="btn btn-outline-primary sm-btn" onClick={() => showTopicBlogs()}>View all articles</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return  (
        <div className="blog-page">
            <Helmet>
                <link rel="canonical" href="https://www.honesttaskers.com/blog"></link>
            </Helmet>
            <div className="blog-header">
                <div className="container">
                   <div className="row">
                        <div className="col-md-6">
                            <div className="bread-crumb">
                                <div className="item" onClick={() => navigate('/')}>Home</div>
                                <div className="seperator">{'>'}</div>
                                <div className="item active">Blog</div>
                            </div>
                            <h2>Blog</h2>
                        </div>
                        <div className="col-md-6 bread-img">
                            <img src={headImg}/>
                        </div>
                   </div>
                </div>
            </div>
            <div className="page-content">
                { posts && <BlogFeatured posts={posts.slice(0, 4)}/>}
                { (posts && posts.length > 4) && (
                    groupedPosts(posts.slice(4)).map((group, index) => (
                        <ArticleSection items={group} key={index}/>
                    ))
                ) }
                { topics && <BlogTopics topics={topics}/> }
                { posts && <Stories posts={posts}/>}
            </div>
        </div>
    )
}