import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/VmsHome/HomeImg.svg';

export default function VmsHomeIntro({onGetStarted}) {

    return (
        <div className="vms-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-6 left-section">
                                    <h1>Virtual Medical Scribing</h1>
                                    <h2>Your Pathway to Seamless Patient Documentation</h2>
                                    <p>Bringing certified scribe-trained <span>registered nurses to</span><span>bridge</span> the gap between healthcare and administrative precision.</p>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Schedule Discovery Call</div>
                                </div>
                                <div className="right-sec col-lg-6">
                                    <img src={homeImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}