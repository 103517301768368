import React from "react";
import './style.scss';
import storyImg from 'src/Assets/Images/Home/OurStory.svg';
import { useNavigate } from "react-router-dom";

export default function Introduction() {
    const navigate = useNavigate()

    return (
        <div className="intro">
            <div className="container">
                <div className="row content">
                    <div className="col-lg-6 left-content">
                        <div className="img-wrapper">
                            <img src={storyImg}/>
                        </div>
                    </div>
                    <div className="col-lg-6 right-content">
                        <h2>Intro To Remote Clinical Chat Auditor</h2>
                        {/* <div className="title">THE GENESIS:</div> */}
                        <p>In the era of digitalized healthcare communication, maintaining accuracy, clarity, and compliance in clinical chats is paramount. HonestTaskers offers dedicated Remote Clinical Chat Auditors to ensure every line of communication upholds the highest clinical standards, protecting both your professionals and the patients they serve.</p>
                        {/* <div className="btn btn-outline-primary mt-3" onClick={() => navigate('/story')}>READ MORE</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}