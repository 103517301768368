import React from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";

export default function CalendlyPopUp({isOpen, onClose }) {

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload),
  });
  
    // const prefill = {
    //     email: 'roland@honesttaskers.com',
    //     firstName: 'Roland',
    //     lastName: 'Omene',
    //     name: 'Roland Omene',
    //     smsReminderNumber: '817 420-7608',
    //     guests: [
    //       'Tony@honesttaskers.com',
    //       'Info@honesttaskers.com',
    //       'kristine@honesttaskers.com'
    //     ],
    //     customAnswers: {
    //       a1: 'a1',
    //       a2: 'a2',
    //       a3: 'a3',
    //       a4: 'a4',
    //       a5: 'a5',
    //       a6: 'a6',
    //       a7: 'a7',
    //       a8: 'a8',
    //       a9: 'a9',
    //       a10: 'a10'
    //     },
    //     date: new Date(Date.now() + 86400000)
    //   };

    const pageSettings={
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055'
      }

    const styles={
        height: '1000px'
      }

    const utm={
        utmCampaign: 'Spring Sale 2019',
        utmContent: 'Shoe and Shirts',
        utmMedium: 'Ad',
        utmSource: 'Facebook',
        utmTerm: 'Spring'
      }

    return (
        <PopupModal
          url="https://calendly.com/honesttaskers/30min"
          pageSettings={pageSettings}
          styles={styles}
          // prefill={prefill}
          onModalClose={() => onClose() }
          open={isOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
        />
    )
}