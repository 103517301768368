import React from "react";
import './style.scss';
import footerLogo from 'src/Assets/Images/VmrHome/FooterLogo.svg';
import { useNavigate } from "react-router-dom";

export default function Footer2() {
    const navigate = useNavigate()

    return (
        <div className="footer-2">
            <div className="wave-bg">
                <div className="logo-wrapper">
                    <img src={footerLogo}/>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="content">
                        <div className="" onClick={() => navigate('/terms-of-service')}>Terms of service</div>
                        <div className="" onClick={() => navigate('/privacy-policy')}>Privacy</div>
                    </div>
                </div>
            </div>
        </div>
    )
}