import React, { useEffect, useState } from "react";
import './style.scss';
import headImg from 'src/Assets/Images/Blog/BlogHImg.svg';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { getTopicBlogsAction } from "src/Redux/Actions";
import { ArticleItem } from "src/Components";

export default function BlogTopicPage() {
    const [pageCount, setPageCount] = useState(12)
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        getTopicPosts_loading,
        topicPosts
    } = useSelector(state => state.Home)

    useEffect(() => {
        showSpinner()
        dispatch(getTopicBlogsAction(params.id))
    }, [])

    useEffect(() => { 
        if (!getTopicPosts_loading) {
            hideSpinner()
            console.log('=== topic blog ===', topicPosts)
        }
    }, [dispatch, getTopicPosts_loading]) 

    const topicName = () => params.topic ? params.topic.replaceAll('-', ' ') : ''

    return (
        <div className="blog-topic-page">
            <div className="blog-header">
                <div className="container">
                   <div className="row">
                        <div className="col-md-6">
                            <div className="bread-crumb">
                                <div className="item" onClick={() => navigate('/')}>Home</div>
                                <div className="seperator">{'>'}</div>
                                <div className="item" onClick={() => navigate('/blog')}>Blog</div>
                                <div className="seperator">{'>'}</div>
                                <div className="item active">{topicName()}</div>
                            </div>
                            <h2>Blogs on {topicName()}</h2>
                        </div>
                        <div className="col-md-6 bread-img">
                            <img src={headImg}/>
                        </div>
                   </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        {
                            topicPosts && topicPosts.slice(0, pageCount).map((item, index) => (
                                <div className="col-lg-3" key={index}>
                                    <ArticleItem item={item}/>
                                </div>
                            ))
                        }
                    </div>
                    { (topicPosts && topicPosts.length > pageCount) && <div className="btn btn-outline-primary sm-btn mt-4" onClick={() => setPageCount(pageCount + 12)}>View More</div>}
                </div>
            </div>
        </div>
    )
}