import React, { useEffect, useState } from "react";
import './style.scss';
import { Modal } from "react-bootstrap";
import _ from 'lodash';
import { toast } from "react-toastify";

export default function RequestInterviewModal({show, onHide, va, onSubmit }) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [time, setTime] = useState('')
    const [zone, setZone] = useState('')

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const isInValid = () => _.isEmpty(name) || _.isEmpty(email) || _.isEmpty(time) || _.isEmpty(zone)

    const submit = () => {
        if (isInValid()) {
            toast("Please enter all required information.")
            return
        } 
        if (!email.match(validRegex)) {
            toast("Please enter a valid email.")
            return
        }
        const formData = new FormData()
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('time', time)
        formData.append('zone', zone)
        formData.append('va_name', va.user.name)
        formData.append('va_email', va.user.email)
        onSubmit(formData)
    }

    return (
        <Modal className="interview-modal" show={show} onHide={onHide}>
            <Modal.Header>
                <div className="modal-title">Enter Your Information</div>
                <div className="close" onClick={onHide}>Close</div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper">
                    <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Phone</label>
                        <input type="tel" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Interview time</label>
                        <input type="time" className="form-control" value={time} onChange={(e) => setTime(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Time zone</label>
                        <input type="text" className="form-control" value={zone} onChange={(e) => setZone(e.target.value)}/>
                    </div>
                    <div className="btn btn-orange btn-md w-100" onClick={() => submit()}>Submit</div>
                </div>
            </Modal.Body>
        </Modal>
    )
}