import React, { useState } from "react";
import './style.scss';
import ellipse from 'src/Assets/Images/Home/QuoteEllipse.svg'
import iconPrev from 'src/Assets/Images/Home/ReviewPrev.svg';
import iconNext from 'src/Assets/Images/Home/ReviewNext.svg';

export default function Reviews({Reviews, className}) {

    const [currentReview, setCurrentReview] = useState(Reviews[0])

    const index = Reviews.findIndex(item => {
        return item.id === currentReview.id
    })

    const onNext = () => {
        const nextReview = index === Reviews.length - 1 ? Reviews[0] : Reviews[index + 1];
        setCurrentReview(nextReview)
    }

    const onPrev = () => {
        const prevReview = index === 0 ? Reviews[Reviews.length - 1] : Reviews[index - 1];
        setCurrentReview(prevReview)
    }

    return (
        <div className={`our-customers ${className}`}>
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 left-section">
                            <div className="info">
                                <h2>Our Customers Love Us</h2>
                                <div className="mobile-sec">
                                    <div className="user-content">
                                    <div className="img-wrapper">
                                        <img src={currentReview.image} className="user-pic"/>
                                        <img src={ellipse} className="ellipse"/>
                                        <div className="user-name">{currentReview.name}</div>
                                    </div>
                                </div>
                                </div>
                                <div className="message">
                                    <p>{currentReview.title}</p>
                                    <div>{currentReview.message}</div>
                                </div>
                            </div>
                            <div className="navigation">
                                <img src={iconPrev} className="prev" onClick={() => onPrev()}/>
                                <img src={iconNext} className="next" onClick={() => onNext()}/>
                                <div className="msg">Next</div>
                            </div>
                        </div>
                        <div className="col-lg-6 right-section">
                            <div className="user-content">
                                <div className="img-wrapper">
                                    <img src={currentReview.image} className="user-pic"/>
                                    <img src={ellipse} className="ellipse"/>
                                    <div className="user-name">{currentReview.name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}