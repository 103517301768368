import { useState } from 'react';
import './style.scss';
import { CourseCurriculum, CourseInstructors, CourseVideoPreview } from 'src/Components';

export default function CourseDetailPage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    return (
        <div className='course-detail-wrapper'>
            <div className='section-wrapper'>
                <section className='section-content container'>
                    <div className='content-1'>
                        <svg className="gradient-edge" preserveAspectRatio="xMinYMin meet" viewBox="0 0 500 50">
                            <path d="M0,50 L0,4 C95,-23 285,115 500,2 L500,50 L0,50 Z" style={{ stroke: "none", fill: "rgb(255, 255, 255)" }} ></path>
                        </svg>
                    </div>
                    <nav className='breadcrumb'>
                        <ul>
                            <li>
                                <span className='breadcrumb-item'><a href=''>Home</a></span>
                            </li>
                            <li>
                                <span className='breadcrumb-item'>
                                    <span className="private-icon private-icon__low" style={{ marginRight: "6px" }}>
                                        <span aria-hidden="true" className="icon"></span>
                                    </span>
                                    <a href='' className=''>Library</a>
                                </span>
                            </li>
                            <li>
                                <span className='breadcrumb-item'>
                                    <span className="private-icon private-icon__low" style={{ marginRight: "6px" }}>
                                        <span aria-hidden="true" className="icon"></span>
                                    </span>
                                    <a href=''>Social Media Marketing Certification Course: Get Certified in Social Media Strategy</a>
                                </span>
                            </li>
                        </ul>
                    </nav>
                    <div className='course-header'>
                        <div>
                            <h2 className='course-name'>Social Media Training:</h2>
                            <h1 className='course-title'>Social Media Marketing Certification Course: Get Certified in Social Media Strategy</h1>
                            <h3 className='sub-title'>FREE Certification Course</h3>
                            <p className='course-summary'>Build a successful social media strategy with HubSpot Academy's free Social Media Marketing Certification Course! Discover how to create engaging content, expand your reach, and measure ROI. Learn how you can use social media to up your business’s brand awareness and attract new customers!</p>
                        </div>
                        <div className='signup-wrapper'>
                            <div className='signup-form'>
                                <h1>Sign up to access this course</h1>
                                <p>100% free. No credit card needed.</p>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" placeholder="Enter your name" value={name} onChange={e => setName(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label>E-mail</label>
                                    <input type="text" className="form-control" placeholder="Enter your email" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>Country</label>
                                    <input type="text" className="form-control" placeholder="Enter your country" value={phone} onChange={e => setPhone(e.target.value)}/>
                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="tel" className="form-control" placeholder="Enter your phone" value={phone} onChange={e => setPhone(e.target.value)}/>
                                </div>
                                <div className="btn btn-orange mt-4 w-100">Submit</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='course-content container'>
                    <div className='block'>
                        <h5>Who is this for?</h5>
                        <div className='block-content'>
                            <ul>
                                <li>Small business owners looking to build out their social media strategy for broader reach and growth</li>
                                <li>Influencers and social media marketers looking to broaden their skillset</li>
                                <li>Marketing managers looking to upscale their digital marketing expertise to include social media marketing fundamentals</li>
                            </ul>
                        </div>
                    </div>
                    <div className='block'>
                        <h5>What you'll learn</h5>
                        <div className='block-content'>
                            <ul>
                                <li>Small business owners looking to build out their social media strategy for broader reach and growth</li>
                                <li>Influencers and social media marketers looking to broaden their skillset</li>
                                <li>Marketing managers looking to upscale their digital marketing expertise to include social media marketing fundamentals</li>
                            </ul>
                        </div>
                    </div>
                    <div className='block fill'>
                        <h5>Includes</h5>
                        <ul className='includes'>
                            <li>
                                <div className='item'>
                                    <span className='icon certificate'></span>
                                    <span>1 Certification</span>
                                </div>
                            </li>
                            <li>
                                <div className='item'>
                                    <span className='icon lesson'></span>
                                    <span>10 Lessons</span>
                                </div>
                            </li>
                            <li>
                                <div className='item'>
                                    <span className='icon videos'></span>
                                    <span>12 Videos</span>
                                </div>
                            </li>
                            <li>
                                <div className='item'>
                                    <span className='icon quiz'></span>
                                    <span>120 Quizzes</span>
                                </div>
                            </li>
                            <li>
                                <div className='item'>
                                    <span className='icon time'></span>
                                    <span>5 hr 19 min</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <CourseVideoPreview video={"https://muse.ai/embed/y8zH87z"}/>
                <CourseInstructors />
                <CourseCurriculum />
            </div>
        </div>
    )
}