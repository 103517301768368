import React from "react";
import './style.scss';
import map from 'src/Assets/Images/Home/map.svg';
import iconPhone from 'src/Assets/Images/Home/IconPhone.png';
import iconLocation from 'src/Assets/Images/Home/IconLocation.png';

export default function GetStarted({onSpeak}) {

    return (
        <div className="get-started">
            <div className="content">
                <div className="map">
                    {/* <img src={map}/> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3415.113300552013!2d-97.3642861823192!3d32.72740563598974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e722d4d972de7%3A0x50a33620da8f9796!2sApple%20University%20Park%20Village!5e0!3m2!1sfr!2scm!4v1700563793599!5m2!1sfr!2scm" style={{ border:"0", width:"100%", height:"660px"}} allowFullScreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="info">
                    <h2>Corporate Office</h2>
                    <div className="contact">
                        <img src={iconPhone}/>
                        <div>817 420-7608</div>
                    </div>
                    <div className="contact">
                        <img src={iconLocation}/>
                        <div>1751 River Run #200 Fort Worth, TX 76107</div>
                    </div>
                    <h2 className="title"><span>Get started</span> with your all-purpose virtual assistant <span>TODAY</span></h2>
                    <div className="btn-wrapper">
                         <div className="btn btn-orange btn-lg mt-2" onClick={() => onSpeak()}>Speak To Sales</div>
                    </div>
                </div>
            </div>
        </div>
    )
}