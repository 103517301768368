import { Api } from "./Api";

export function* submitContact(data) {
    const jsonData = yield Api.formPost("/contact", data)
    return jsonData
}

export function* submitReceptionContact(data) {
    const jsonData = yield Api.formPost("reception/contact", data)
    return jsonData
}