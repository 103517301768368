import React, { useEffect, useState } from "react";
import './style.scss'
import storyImg from 'src/Assets/Images/Blog/StoryImg.png';
import { useNavigate } from "react-router-dom";

export default function Stories({posts}) {
    const navigate = useNavigate()
    const [pageIndex, setPageIndex] = useState(12)

    useEffect(() => {
        console.log(posts.slice(0, pageIndex))
    })

    const formatDate = (value) => {
        const date = new Date(value)
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }


    function StoryItem({item}) {
        return (
            <div className="story-item" onClick={() => navigate(`/blog/${item.slug}`)}>
                <div className="top-info">
                    <div className="date">{formatDate(item.created_at.date)}</div>
                    <div className="type">{item.category.name}</div>
                </div>
                <div className="content">
                    <div className="title">{item.title}</div>
                    <div className="img-wrapper">
                        <img src={item.image} alt={item.image_alt}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="stories">
            <div className="container">
                <div className="title">ALL STORIES</div>
                <div className="row">
                    {
                        posts.slice(0, pageIndex)
                            .map((item, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <StoryItem item={item}/>
                                </div>
                            ))
                    }
                </div>
                {
                    pageIndex <= posts.length && (
                        <div className="d-flex j-c mt-4">
                            <div className="btn btn-outline-primary" onClick={() => setPageIndex(pageIndex + 12)}>Load more</div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}