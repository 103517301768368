
import { 
    SUBMIT_CONTACT, SUBMIT_RECEPTION_CONTACT,
} from "../Type";

export function submitContactAction(data) {
    return {
        type: SUBMIT_CONTACT,
        data
    }
}

export function submitReceptionContactAction(data) {
    return {
        type: SUBMIT_RECEPTION_CONTACT,
        data
    }
}