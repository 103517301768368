import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";

export default function BenefitsOfHiring() {

    const BenefitsList = [
        {
            title: "BOOST PRODUCTIVITY",
            message: "Lower overhead costs, attract new patients through targeted social media campaigns, and benefit from a stable employee retention rate.",
            color: "green"
        },
        {
            title: "Cost-Effective Solutions",
            message: "Opt for our virtual medical assistants and witness considerable savings. Avoid expenses related to full-time salaries, benefits, and infrastructure.",
            color: "orange"
        },
        {
            title: "Versatile Expertise",
            message: "Our assistants aren't just multi-talented, they specialize in Scribing, Administration, Billing, and Marketing, ensuring all-round support for your practice.",
            color: "red"
        },
        {
            title: "Lifelong Learning",
            message: "At HonestTaskers, continuous education is a promise. Even post-placement, our team strives to enhance their knowledge, driving added value to your practice.",
            color: "green"
        },
        {
            title: "Focus on What Matters",
            message: "Delegate and breathe easy. This way, medical professionals can prioritize patient care and other essential duties, optimizing practice efficiency.",
            color: "orange"
        },
        {
            title: "Revenue Boosters:",
            message: "Experience no downtime. In slower periods, our team actively promotes your practice on social media and online channels, ensuring consistent visibility.",
            color: "red"
        },
        {
            title: "HIPAA-Compliant:",
            message: "Rest assured, our virtual assistants rigorously follow HIPAA guidelines, ensuring utmost data privacy and safety for healthcare providers.",
            color: "green"
        },
        {
            title: "Guarding Your Online Presence:",
            message: "Expertise extends to maintaining your digital reputation across platforms, including Google Business Profile. We ensure your practice always makes a stellar first impression.",
            color: "orange"
        },
        {
            title: "Stability in Staffing:",
            message: "Say goodbye to frequent turnover disruptions. With HonestTaskers, continuity is a guarantee.",
            color: "red"
        },
        {
            title: "Always Available:",
            message: "Experience seamless services tailored to your needs. From sudden task influxes to long-term requirements, HonestTaskers is your dependable ally.",
            color: "green"
        },
        {
            title: "Elite & Trustworthy Team:",
            message: "A testament to our quality – 95% of HonestTaskers possess bachelor's or associate's degrees in nursing. Trained in scribing, medical assisting, billing, and even digital marketing.",
            color: "orange"
        },
        {
            title: "Growth Ready:",
            message: "As your practice expands, rely on HonestTaskers to scale support effortlessly, bypassing traditional hiring hurdles.",
            color: "red"
        }
    ]

    function BenefitCard({item, index}) {
        return (
            <div className={`b-card ${item.color}`}>
                <div className="title-wrapper">
                    <div className="title">{item.title}</div>
                </div>
                <div className="message">{item.message}</div>
                <div className="num-bg"></div>
                <div className="num">{index < 9 ? `0${index+1}` : `${index+1}`}</div>
            </div>
        )
    }

    return (
        <div className="benefits">
            <div className="inner-bg">
                <div className="container">
                    <div className="content">
                        <h2>Benefits of Hiring</h2>
                        <p>HonestTaskers:</p>
                    </div>
                </div>
                <div className="container slider-container">
                    <div className="benefits-slider">
                        <Media
                            queries={{
                                lg: "(min-width: 1655px)",
                                md: "(min-width: 1360px) and (max-width: 1654px)",
                                sm: "(min-width: 1032px) and (max-width: 1359px)",
                                normal: "(max-width: 1031px)",
                            }}
                        >
                            {(matches) => (
                                <OwlCarousel 
                                    className="owl-theme"
                                    stagePadding={
                                        matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                                    }
                                    margin={
                                        matches.lg ? 220 : matches.md ? 200 : matches.sm ? 45 : 50
                                    }
                                    items={matches.lg ? 5 : matches.md ? 4 : matches.sm ? 3 : 1}
                                    dots
                                    loop
                                    autoplay
                                    rewind
                                    autoplaySpeed={500}
                                    autoplayHoverPause
                                    autoplayTimeout={2000}
                                >
                                    {
                                        BenefitsList.map((item, index) => (
                                            <BenefitCard item={item} key={index} index={index}/>
                                        ))
                                    }
                                </OwlCarousel>
                            )}
                        </Media>
                    </div>
                </div>
                {/* <div className="mobile-slider">
                    <div className="container">
                        <div className="row">
                            {
                                BenefitsList.map((item, index) => (
                                    <div className="col-md-6" key={index}>
                                        <BenefitCard item={item} key={index} index={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}