import React from "react";
import './style.scss';
import FaqAccordion from "./FaqAccordion";
import { useLocation, useNavigate } from "react-router-dom";


export default function FAQs({faqs}) {
    const navigate = useNavigate()
    const location = useLocation()

    const slicePathName = () => location.pathname === '/' || location.pathname == '/multi-purpose-virtual-medical-assistant'

    return (
        <div className="faqs">
            <div className="container">
                <h2>Frequently Asked Questions</h2>
                <div className="row">
                    {
                        faqs.slice(0, slicePathName() ? 8 : faqs.length).map((item, index) => (
                            <div className="col-lg-6" key={index}>
                                <FaqAccordion item={item}/>
                            </div>
                        ))
                    }
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <div className="btn btn-outline-primary" onClick={() => navigate('/faqs')}>See More</div>
                </div>
            </div>
        </div>
    )
}