import React from "react";
import { useDispatch } from "react-redux";
import { Benefits, Duties, FAQs, Footer2, GetStarted2, LandingBottom, Pricing, VdrHomeIntro, VdrIntroduction } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { VDRFaqs, VDRWhyChooseUs, VdrBenefitsList, faqs, getFAQStructuralData, getImageStructuralData, getPricingPlans, virtualDentalRDuties } from "src/Constant";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";

export default function VdrHomepage() {
    const dispatch = useDispatch()
    const pricingTitle = "We offer a range of tailored packages to cater to diverse practice<br> sizes and requirements. From basic to advanced, our solutions<br> are designed to provide unmatched value and efficiency."

    const meta_title = "Professional Virtual Dental Receptionists | Honest Taskers"
    const meta_description = "Transform your dental practice with Honest Taskers’ Virtual Receptionists. Save over 60% on costs while enhancing efficiency and patient care. Get started today!"
    const meta_image = "https://honesttaskers.com/virtual_dental_receptionist.png"

    return (
        <div className="vdr-landing">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/virtual-dental-receptionist"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(VDRFaqs))}</script>
            </Helmet>
            <VdrHomeIntro onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <LandingBottom />
            <VdrIntroduction />
            <Benefits subTitle={"of Virtual Dental Receptionist"} benefits={VdrBenefitsList}/>
            <Duties duties={virtualDentalRDuties} title={"Duties & Responsibilities"} subTitle={"of Virtual Dental Receptionist"} />
            <WhyChooseUs whyChoose={VDRWhyChooseUs} subTitle={"HonestTaskers' Virtual Dental Receptionists"}/>
            <FAQs faqs={VDRFaqs}/>
            <Pricing subTitle={pricingTitle} plans={getPricingPlans('pricing-one')} hasColor={true}/>
            <GetStarted2 
                title1={"your dental practice's administrative efficiency?"}
                title2={"Click below to get started or contact us for more details on tailoring a solution that's perfect for you!"}
                onGetStarted={() => dispatch(toggleCalendlyModal(true))}
                />
            <Footer2 />
        </div>
    )
}