import React, { useEffect } from "react";
import './style.scss';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { getBlogAction } from "src/Redux/Actions";
import _ from 'lodash';
import { FeaturedBlog } from "src/Components";
import { Helmet } from "react-helmet";

export default function BlogDetailPage() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const {
        post,
        posts,
        getPost_loading
    } = useSelector(state => state.Home)

    useEffect(() => {
        showSpinner()
        dispatch(getBlogAction(params.slug))
    }, [dispatch])

    useEffect(() => {
        if (!getPost_loading) {
            hideSpinner()
            console.log("=== post ===", post)
        }
    }, [post, getPost_loading])

    const getUserName = (item) => {
        if (_.isEmpty(item.user.honorific)) return item.user.name
        return `${item.user.honorific}. ${item.user.name}`
    }

    const formatDate = (value) => {
        const date = new Date(value)
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }

    const getImage = (url) => url.replace("https://api.yellowbagcleaners.com", "https://phplaravel-513670-4057006.cloudwaysapps.com")

    const getStructuralData = (post) => {
        const articleStructuredData = {
            "@context": "https://schema.org",
            "@type": "Article",
            headline: post.title,
            description: post.content,
            image: post.image,
            datePublished: !_.isEmpty(post.published_date) ? new Date(post.published_date).toISOString() : '',
            author: {
              "@type": "Person",
              name: post.user.name,
            //   url: "https://twitter.com/johnny_reilly",
            },
          };
        return articleStructuredData;
    }

    return (
        <>
        {post && (<Helmet>
            <title>{post.title}</title>
            <meta name="title" content={post.title} />
            <meta name="description" content={post.excerpt} />
            <meta property="type" content="website" />
            <meta property="url" content={`${window.location.origin}/blog/${post.slug}`}/>
            <meta property="image" content="https://honesttaskers.com/preview.png" />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={post.title} />
            <meta itemprop="description" content={post.excerpt} />
            <meta itemprop="image" content="https://honesttaskers.com/preview.png" />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={`${window.location.origin}/blog/${post.slug}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.excerpt} />
            <meta property="og:image" content="https://honesttaskers.com/preview.png" />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={post.title}/>
            <meta name="twitter:description" content={post.excerpt}/>
            <meta name="twitter:image" content="https://honesttaskers.com/preview.png"/>
            { post && <script type="application/ld+json">{JSON.stringify(getStructuralData(post))}</script> }
            </Helmet>)}
            <div className="blog-detail">
            {
                post && (
                    <div className="container">
                        <div className="bread-crumb">
                            <div className="item active" onClick={() => navigate('/')}>Home</div>
                            <div className="seperator active">{'>'}</div>
                            <div className="item active" onClick={() => navigate('/blog')}>Blog</div>
                            <div className="seperator active">{'>'}</div>
                            <div className="item">{post.title}</div>
                        </div>
                        <div className="blog-content">
                            <div className="d-flex j-b a-c flex-wrap">
                                <div className="category">{post.category.name}</div>
                                <div className="date">{formatDate(post.created_at.date)}</div>
                            </div>
                            <div className="title">{post.title}</div>
                            <div className="blog-user">
                                <div className="img-wrapper">
                                    <img src={getImage(post.user.picture)}/>
                                </div>
                                <div className="info">
                                    <div className="">{getUserName(post)}</div>
                                    <div className="role">{post.user.title}</div>
                                </div>
                            </div>
                            <div className="blog-img">
                                <img src={getImage(post.image)} />
                            </div>
                            <div className="blog-desc" dangerouslySetInnerHTML={{ __html: post.content }}></div>
                        </div>
                    </div>
                )
            }
        </div>
        { posts && <FeaturedBlog className={"gray"} posts={posts}/>   }                                         
        </>
    )
}