import { GET_VA_PROFILE, TOGGLE_SKILL_MODAL } from "../Type"


export const toggleSkillModal = (show, skill ) => {
    return {
        type: TOGGLE_SKILL_MODAL,
        data: { show: show, skill: skill }
    }
}

export const getVAProfileAction = (id) => {
    return {
        type: GET_VA_PROFILE,
        id
    }
}