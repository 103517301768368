import React from "react";
import { useDispatch } from "react-redux";
import { Benefits, FAQs, Footer2, GetStarted2, Pricing, TTMedicalHomeIntro, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";

export default function TTMedicalHomepage() {
    const dispatch = useDispatch()

    const benefits = [
        {
            title: "Rapid Response:",
            message: "Ensure your patients get immediate attention and guidance.",
            color: "green"
        },
        {
            title: "Patient Safety:",
            message: "By gauging the urgency of each case, direct patients to the appropriate level of care.",
            color: "green"
        },
        {
            title: "Efficient Call Handling:",
            message: "Reduce wait times and optimize your call flows.",
            color: "green"
        },
        {
            title: "Enhanced Patient Trust:",
            message: "Assure patients that they're in safe and capable hands, even before they see a physician.",
            color: "green"
        },
        {
            title: "Cost-Effective:",
            message: "Minimize unnecessary ER visits and prioritize critical cases.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "Medical Screening:",
            message: "Rapidly assess the patient's condition based on the information provided."
        },
        {
            title: "Prioritization:",
            message: "Directing the caller to the appropriate level of care – ER, urgent care, GP, or self-care."
        },
        {
            title: "Documentation:",
            message: "Logging calls, ensuring patient information is updated and easily accessible for further treatment."
        },
        {
            title: "Follow-ups:",
            message: "Calling back to check on patients who were advised self-care or given certain instructions."
        },
        {
            title: "Liaising with Healthcare Providers:",
            message: "Keeping doctors informed on critical cases or specific patient concerns."
        }
    ]

    const whyChooseList = [
        {
            title: "Extensive Training:",
            message: "Our triage assistants undergo rigorous training to handle medical emergencies and standard procedures."
        },
        {
            title: "Compassionate Handling:",
            message: "Understanding and empathy are at the core of our services, ensuring patients feel cared for."
        },
        {
            title: "Consistent Performance:",
            message: "With round-the-clock quality checks, expect high-quality service every time."
        },
        {
            title: "HIPAA Compliance:",
            message: "Patient confidentiality is paramount. We strictly adhere to data protection standards."
        },
        {
            title: "Versatility:",
            message: "Capable of handling a wide range of medical queries and emergencies."
        }
    ]

    const faqs = [
        {
            question: "How do your triage assistants stay updated with medical protocols?",
            answer: "Regular training sessions and workshops ensure they stay abreast with the latest medical guidelines and protocols."
        },
        {
            question: "What's the process for handling severe emergencies?",
            answer: "In case of severe emergencies, the assistant will direct the caller to the nearest ER and advise immediate medical attention."
        },
        {
            question: "Do you provide multilingual support?",
            answer: "Yes, we have a diverse team that can cater to multiple languages, ensuring wider patient accessibility."
        },
        {
            question: "How do you ensure quick response times during peak hours?",
            answer: "We employ a scalable model, which allows us to allocate additional resources during high demand, ensuring consistent response times."
        }
    ]

    const meta_title = "Telephone Triage Medical Assistants | Honest Taskers"
    const meta_description = "Boost your practice's efficiency with Honest Taskers’ Telephone Triage Medical Assistants. Experience over 60% cost savings and superior patient triage services. Reach out now!"
    const meta_image = "https://honesttaskers.com/vmr_image.png"

    return (
        <div className="ttmedical-hompeage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/telephone-triage-medical-assistant"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <TTMedicalHomeIntro onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <Benefits subTitle={"of Telephone Triage Medical Assistant"} benefits={benefits}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities of Telephone Triage Medical Assistant"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Telephone Triage Assistants?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs}/>
            <Pricing hasColor={true} plans={getPricingPlans('no-custom', false, true)} btnText="Contact Us For Pricing"/>
            <GetStarted2 title={"Empower Your Telephonic Patient Interactions."}
                btnText="Engage with a Triage Expert Today!"
                onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <Footer2 />
        </div>
    )
}