import React, { useEffect, useRef } from "react";
import './style.scss';
import forwardIcon from 'src/Assets/Images/About/ForwardIcon.svg';
import aboutImg from 'src/Assets/Images/About/AboutImg.svg';
import { MissionVision, TeamInfo } from "src/Components/About";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function AboutUsPage() {
    const navigate = useNavigate()
    const { hash } = useLocation()
    const teamRef = useRef(null)
    const missionRef = useRef(null)

    useEffect(() => {
        if (hash === '#team') {
            teamRef.current?.scrollIntoView({behavior: 'smooth'});
        } else if (hash === '#mission') {
            missionRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [hash])

    const meta_title = "About Honest Taskers: Comprehensive Remote Medical Staffing Solutions"
    const meta_description = "Discover Honest Taskers: Excellence in remote medical staffing. Offering virtual assistance, scribing, admin tasks, billing, and marketing services to elevate healthcare practices. Your partner in enhancing efficiency and quality."
    const meta_image = "https://honesttaskers.com/preview.png"

    return (
        <>
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://www.honesttaskers.com/about"></link>
            </Helmet>
            <div className="about-page">
                <div className="top-section-wrapper">
                    <div className="head-section">
                        <div className="inner-bg">
                            <div className="about-intro">
                            <div className="container">
                                    <div className="bread-crumb">
                                        <div className="item" onClick={() => navigate('/')}>Home</div>
                                        <div className="seperator">{'>'}</div>
                                        <div className="item active">About Us</div>
                                    </div>
                                    <h2>Ahout Us</h2>
                                    <div className="bottom-section">
                                        <div className="row row-sm">
                                            <div className="col-lg-6 links">
                                                <div className="link" onClick={() => navigate('/story')}>
                                                    <h2 className="mb-0">Our Story</h2>
                                                    <img src={forwardIcon}/>
                                                </div>
                                                <div className="link">
                                                    <h2 className="mb-0" onClick={() => navigate('/why-us')}>Why Us</h2>
                                                    <img src={forwardIcon}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 ">
                                                <img src={aboutImg} className="about-img"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={teamRef}><TeamInfo /></div>
                <div ref={missionRef}><MissionVision /></div>
            </div>
        </>
    )
}