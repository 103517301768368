import { TOGGLE_PORTAL_SIDE_MENU } from "../Type"

const INITIAL = {
    portalSideMenuOpen: false,
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case TOGGLE_PORTAL_SIDE_MENU: {
            const { isOpen } = action.data
            return {
                ...state,
                portalSideMenuOpen: isOpen
            }
        }
        default:
            return state
    }
}