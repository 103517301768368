import React from "react";
import './style.scss';
import { ArticleItem } from "../Blog";

export default function FeaturedBlog({posts, className}) {

    return (
        <div className={`featured-blog ${className}`}>
            <div className="container">
                <h2>Featured Blog</h2>
                <div className="row">
                    {
                        posts.slice(0, 4).map((item, index) => (
                            <div className="col-lg-3" key={index}>
                                <ArticleItem item={item}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}