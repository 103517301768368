import React from "react";
import './style.scss';
import { CaseStudyData } from "./info";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { CaseStudyArrowLeft, CaseStudyArrowRight } from "src/Constant";


export default function CaseStudies() {


    function CaseStudyItem({item}) {

        return (
            <div className="case-item">
                <div className="img-wrapper">
                    <img src={item.image} />
                </div>
                <div className="title">{item.title}</div>
                <div className="case-details">
                    <div className="row">
                        <div className="col-lg-4 item">
                            <div className="num-wrapper green">01</div>
                            <div className="sub-title">{item.challenge.title}</div>
                            <p className="message">{item.challenge.message}</p>
                            <div className="load-more">Load more</div>
                        </div>
                        <div className="col-lg-4 item">
                            <div className="num-wrapper orange">02</div>
                            <div className="sub-title">{item.solution.title}</div>
                            <p className="message">{item.solution.message}</p>
                            <div className="load-more">Load more</div>
                        </div>
                        <div className="col-lg-4 item">
                            <div className="num-wrapper red">03</div>
                            <div className="sub-title">{item.outcome.title}</div>
                            <p className="message">{item.outcome.message}</p>
                            <div className="load-more">Load more</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="case-studies">
            <div className="container">
                <div className="content">
                    <h2>Case Studies Highlight</h2>
                    <div className="carousel-wrapper">
                        <OwlCarousel
                            className="owl-theme"
                            items={1}
                            nav
                            dots={false}
                            navText={[CaseStudyArrowLeft, CaseStudyArrowRight]}
                        >
                            {
                                CaseStudyData.map((item, index) => (
                                    <CaseStudyItem item={item} key={index}/>
                                ))
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}