import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/VmsHome/HomeImg.svg';

export default function MPVHomeIntro({onGetStarted}) {

    return (
        <div className="rcc-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-6 left-section">
                                    <h1>Multipurpose Virtual</h1>
                                    <h2>Medical Assistant</h2>
                                    <p>Trained to Support Practices with Scribing, Administrative Tasks, Billing, 
                                        and Marketing Streamlining Operations, Boosting Profitability, and Saving You Time.</p>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Schedule Discover Call</div>
                                </div>
                                <div className="right-sec col-lg-6">
                                    <img src={homeImg} alt="Multipurpose Virtual Medical Assistant"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}