import React from "react";
import './style.scss';

export default function Achievement({item}) {

    return (
        <div className="achievement">
            <div className="logo-wrapper">
                { item.icon ? <img src={item.icon}/> : item.title ? item.title.charAt(0) : 'A' }
            </div>
            <div className="content">
                <div className="name">{item.title}</div>
                <p dangerouslySetInnerHTML={{ __html: item.description}}></p>
            </div>
        </div>
    )
}