import { useState } from 'react';
import './style.scss';

export default function CourseCurriculum() {

    function LessonItem() {
        const [isOpen, setIsOpen] = useState(false)

        return (
            <li>
                <div> 
                    <div className={`c-item-row ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                        <div className='item-logo'>
                            <span>Developing a Social Media Strategy</span>
                        </div>
                        <div className='row-content'>
                            <h2>Developing a Social Media Strategy</h2>
                            <ul className='lesson-time'>
                                <li>Lesson</li>
                                <li>54 mins</li>
                            </ul>
                        </div>
                        <div className='read-more'>
                            <span className='icon'></span>
                            <h2>Read more</h2>
                        </div>
                    </div>
                    <div className={`c-content ${isOpen ? 'show' : '' }`}>
                        <div className='c-inner'>
                            <div className='summary'>
                                <h2>Lesson instructor: Crystal King</h2>
                                <p>Creating a promotional plan that takes advantage of social and digital technologies will help you shape the conversation, build loyalty, and attract new customers and partners. Social media shouldn’t replace other inbound promotional methods like email, events, or public relations, but instead should augment them, offering another set of marketing channels for you to explore. NOTE: If you previously watched videos from the Inbound Marketing Certification (after March 2018), you may see portions of this lesson marked as complete.</p>
                                <a>See full details</a>
                            </div>
                            <a className='start-course'>Start course</a>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <div className='curriculum'>
            <div className='container'>
                <div className='padding'>
                    <h2>Curriculum</h2>
                    <ul className='c-wrapper'>
                        {
                            Array(5).fill(true).map((item, index) => (
                                <LessonItem key={index} />
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}