import React from "react";
import './style.scss';
import imgRight from 'src/Assets/Images/VmsHome/vmGetStarted.svg';

export default function VmGetStarted({
    onGetStarted,
    btnText="Get Started",
    title = "Ready to transform your documentation process with certified expertise? Let HonestTaskers lead the way."
}) {

    return (
        <div className="vm-get-started">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2>{title}</h2>
                        <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>{btnText}</div>
                    </div>
                    <div className="col-md-6 right-sec">
                        <img src={imgRight}/>
                    </div>
                </div>
            </div>
        </div>
    )
}