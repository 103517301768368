import React, { useState } from "react";
import './style.scss';
import missionImg from 'src/Assets/Images/About/MissionImg.svg';

export default function MissionVision() {

    const [tab, setTab] = useState('vision')

    return (
        <div className="mission-vision">
            <div className="inner-bg">
                <div className="container">
                    <div className="content">
                        <div className="row">
                            <div className="col-lg-6 left-content">
                                <div className="title-sec">
                                    <div className={tab === 'vision' ? 'active': ''} onClick={() => setTab('vision')}>Vision</div>
                                    <div className={tab === 'mission' ? 'active' : ''} onClick={() => setTab('mission')}>Mission</div>
                                </div>
                                {
                                    tab === 'vision' && (
                                        <div className="message">
                                            Be the best place for virtual medical assistants to work and deliver joy through services
                                        </div>
                                    )
                                }
                                {
                                    tab === 'mission' && (
                                        <div className="message">
                                            Be the best place for virtual medical assistants to work and deliver joy through services
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-lg-6 right-content">
                                <img src={missionImg}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}