import caseImg1 from 'src/Assets/Images/Home/case-study-1.png';
import caseImg2 from 'src/Assets/Images/Home/case-study-2.png';
import caseImg3 from 'src/Assets/Images/Home/case-study-3.png';

export const CaseStudyData = [
    {
        title: "Sunset Psychiatric Services",
        image: caseImg1,
        challenge: {
            title: "Challenge",
            message: "Overburdened Staff: Dr. Lisa Martinez, the leading psychiatrist at Sunset Psychiatric Services, along with her small team, found themselves drowning in administrative tasks. The paperwork, billing, and record-keeping were consuming much of their time that should have been dedicated to patient care."
        },
        solution: {
            title: "Solution",
            message: "Hiring HonestTaskers' Multi-purpose Virtual Medical Assistant: After considering the challenges, Sunset Psychiatric Services partnered with HonestTaskers to leverage their expertise in providing a multi-skilled Virtual Medical Assistant (VMA)."
        },
        outcome: {
            title: "Outcome After 6 Months of Using Services:",
            message: "Enhanced Patient Engagement: With the VMA handling administrative tasks, Dr. Martinez found herself with more time for patient care, leading to better patient engagement and improved satisfaction rates."
        }
    },
    {
        title: "Calm Minds Psychiatric Clinic",
        image: caseImg2,
        challenge: {
            title: "Challenge",
            message: "Documentation Overload: Dr. Smith, the primary psychiatrist at Calm Minds, was overwhelmed with the volume of patient notes and documentation that required transcription. Inactive Digital Footprint: Despite delivering quality psychiatric care, Calm Minds had minimal ..."
        },
        solution: {
            title: "Solution",
            message: "Scribing Assistance: Calm Minds integrated a specialized HonestTaskers Virtual Medical Assistant trained in medical scribing. This ensured that Dr. Smith could focus on patient care while the VMA handled documentation.  "
        },
        outcome: {
            title: "Outcome After 6 Months of Using Services:",
            message: "Efficient Documentation: The clinic noted a 50% reduction in documentation time, leading to increased patient face-time and enhanced care. Vibrant Digital Engagement: With an active presence on major platforms and improved online reputation... "
        }
    },
    {
        title: "Harbor Mental Health Clinic",
        image: caseImg3,
        challenge: {
            title: "Challenge",
            message: "Time-Consuming Administrative Tasks: Dr. James Harper found his focus drifting from patient care due to the overwhelming administrative demands of his growing practice. Lack of Online Visibility: Despite offering specialized treatments, the clinic had minimal online presence..."
        },
        solution: {
            title: "Solution",
            message: "Deploying HonestTaskers' VMA: Harbor Mental Health Clinic integrated an HonestTaskers Virtual Medical Assistant (VMA) to manage their administrative and online marketing needs. Boosted Online Presence: The VMA revamped their digital presence, promoting the clinic's unique... "
        },
        outcome: {
            title: "Outcome After 9 Months of Using Services:",
            message: "Increased Patient Interaction: With a reduced administrative load, Dr. Harper could increase his patient interactions by 30%. Online Growth: The clinic observed a 20% growth in online bookings, owing to the enhanced digital visibility curated by the VMA."
        }
    }
]