import React from "react";
import { CourseProgress } from "src/Components";

export default function CourseProgressPage() {

    return (
        <div className="course-progress-page">
            <CourseProgress />
        </div>
    )
}