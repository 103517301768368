import { Routes, Route} from 'react-router-dom';
import React from 'react';
import { PortalHeader } from 'src/Components';
import { CourseProgressPage, PortalHomePage } from 'src/Pages';

export default function AuthRoutes() {

    return (
        <div>
            <PortalHeader />
            <div className='portal-content'>
                <Routes>
                    <Route exact path='/dashboard' element={<PortalHomePage />} />
                    <Route exact path='/course/:id' element={<CourseProgressPage />} />
                </Routes>
            </div>
        </div>
    )
}