import React from "react";
import './style.scss';
import iconCheck from 'src/Assets/Images/Landing/GreenCheck.svg';

export default function LandingBottom({hour = 'Starts at $10/hr '}) {

    return (
        <div className="landing-bottom">
            <div className="container">
                <div className="content-info">
                    <div className="item">
                        <img src={iconCheck} className="icon"/>
                        <div className="text green">{hour}</div>
                    </div>
                    <div className="item">
                        <img src={iconCheck} className="icon"/>
                        <div className="text">FREE</div>
                        <div className="text green">14 Days Trial</div>
                    </div>
                    <div className="item">
                        <img src={iconCheck} className="icon"/>
                        <div className="text green">HIPPA Compliant</div>
                    </div>
                </div>
            </div>
        </div>
    )
}