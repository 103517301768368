import { toast } from 'react-toastify';
import {
    SUBMIT_CONTACT,
    SUBMIT_CONTACT_SUCCESS,
    SUBMIT_RECEPTION_CONTACT,
    SUBMT_CONTACT_FAIL
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';

import {
    submitContact as submitContactApi,
    submitReceptionContact as submitReceptionContactApi
} from 'src/Api/Contact';

function* submitContact(payload) {
    try {
        const result = yield submitContactApi(payload.data);
        toast("Message sent successfully!")
        if (result && result.data) {
          yield put({ type: SUBMIT_CONTACT_SUCCESS, data: result.data });
        } else {
          yield put({ type: SUBMT_CONTACT_FAIL, data: null });
        }
      } catch (err) {
        alert(err);
      }
}

export function* watchSubmitContact() {
    yield(takeLatest(SUBMIT_CONTACT, submitContact))
}

function* submitReceptionContact(payload) {
  try {
      const result = yield submitReceptionContactApi(payload.data);
      toast("Message sent successfully!")
      if (result && result.data) {
        yield put({ type: SUBMIT_CONTACT_SUCCESS, data: result.data });
      } else {
        yield put({ type: SUBMT_CONTACT_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}

export function* watchSubmitReceptionContact() {
  yield(takeLatest(SUBMIT_RECEPTION_CONTACT, submitReceptionContact))
}