import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/Landing/DentalAssistantIntro.png';

export default function VDAssistantHomeIntro({onGetStarted}) {

    return (
        <div className="vda-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container-fluid">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-5 left-section">
                                    <h1>Virtual Dental Assistant</h1>
                                    <h2>Hire Virtual Dental Assistant for Your Dental Practice</h2>
                                    <p>Maximize efficiency, elevate patient care, and streamline administrative tasks with our skilled Virtual Dental Assistants with 60% cost savings</p>
                                    {/* <p>Streamline appointments, enhance patient communications, and optimize office operations, all at a fraction of the cost.</p> */}
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Empower Your Practice Today</div>
                                </div>
                                <div className="right-sec col-lg-7">
                                    <img src={homeImg} alt="virtual dental assistant"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}