import React from "react";
import './style.scss';

export default function CatalogSection() {

    return (
        <div className="catalog">
            <div className="container">
                <div className="filter-section">
                    <div className="cat-btn-wrapper dropdown">
                        <button className="cat-btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            Catalog
                            <span className="fa fa-angle-down ms-2"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li className="title"><a class="dropdown-item" href="#">Categories</a></li>
                            <li><a class="dropdown-item" href="#">Medical Assistant</a></li>
                            <li><a class="dropdown-item" href="#">Dental Assistant</a></li>
                        </ul>
                    </div>
                    <div className="search-field-wrapper">
                        <div className="search-field">
                            <input name="search" className="form-control" placeholder="Search.."/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}