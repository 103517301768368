import React from "react";
import './style.scss';
import member1 from 'src/Assets/Images/About/Team1.svg';
import member2 from 'src/Assets/Images/About/Team2.svg';
import member3 from 'src/Assets/Images/About/Team3.svg';
import member4 from 'src/Assets/Images/About/Team4.svg';

export default function TeamInfo() {

    const team = [
        {
            image: member1,
            name: "Roland Omene",
            role: "Co Founder & CEO"
        },
        {
            image: member2,
            name: "Dr. Zian Omene",
            role: "Co-Founder, Chief Medical Officer"
        },
        // {
        //     image: member3,
        //     name: "Kristine Bougas",
        //     role: "Lead Recruiter"
        // },
        // {
        //     image: member4,
        //     name: "Gigi Soriano",
        //     role: "Lead Trainer US RN"
        // }
    ]

    return (
        <div className="team-info">
            <div className="container">
                <div className="content">
                    <h2>Team Information</h2>
                    <div className="team-row row">
                        {
                            team.map((item, index) => (
                                <div className="col-lg-3 col-md-6 col-xs-6" key={index}>
                                    <div className="team-member">
                                        <img src={item.image}/>
                                        <div className="name">{item.name}</div>
                                        <div className="role">{item.role}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}