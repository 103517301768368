import React from "react";
import './style.scss';
import dateIcon from 'src/Assets/Images/Policy/iconDate.svg';
import { PrivacyPolicyList } from "src/Constant";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function PrivacyPolicyPage() {
    const navigate = useNavigate();

    function PolicyItem({item, index}) {

        return (
            <div className="policy-item">
                <div className="img-wrapper">
                    <img src={item.img}/>
                </div>
                <div className="info-content">
                    <div className="title"><span>{index < 9 ? '0' : ''}{index+1}</span> {item.title}</div>
                    <div>{item.message}</div>
                    {
                        item.list.length > 0 && <ul>
                            {
                                item.list.map((lItem, lIndex) => (
                                    <li key={lIndex}>{lItem}</li>
                                ))
                            }
                        </ul>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="privacy-page">
            <Helmet>
                <link rel="canonical" href="https://www.honesttaskers.com/privacy-policy"></link>
            </Helmet>
            <div className="privacy-header">
                <div className="container">
                    <div className="bread-crumb">
                        <div className="item" onClick={() => navigate('/')}>Home</div>
                        <div className="seperator">{'>'}</div>
                        <div className="item active">Privacy Policy</div>
                    </div>
                    <h2>Privacy Policy for HonestTaskers</h2>
                    <div className="date">
                        <img src={dateIcon} />
                        <div>10.17.23</div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="content">
                    <div className="row">
                        {
                            PrivacyPolicyList.map((item, index) => (
                                <div className="col-lg-6" key={index}>
                                    <PolicyItem item={item} index={index}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}