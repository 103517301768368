import React from "react";
import './style.scss';

export default function WhyChooseUs({whyChoose, subTitle, title = 'Why Choose', className}) {

    function WhyChooseItem({item, index}) {

        return (
            <div className="col-md-6">
                <div className="why-item">
                    <div className="num-wrapper">{index<9?'0':''}{index+1}</div>
                    <div className="content-info">
                        <div className="title">{item.title}</div>
                        <div>{item.message}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`why-choose-us ${className}`}>
            <div className={`inner-bg ${className}`}>
                <div className="container">
                    <h2>{title}</h2>
                    <h3>{subTitle}</h3>
                    <div className="row">
                        {
                            whyChoose.map((item, index) => (
                                <WhyChooseItem item={item} index={index} key={index}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}