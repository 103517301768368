import React, { useRef, useState } from "react";
import './style.scss';
import logo from 'src/Assets/Images/Home/logo.svg';
import menu from 'src/Assets/Images/Home/HeadMenu.svg';
import close from 'src/Assets/Images/Home/Close.svg';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { togglePortalSideMenu } from "src/Redux/Actions/Portal";
import { ReactSVG } from "react-svg";

export default function PortalHeader() {
    const [showMenu, setShowMenu] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        portalSideMenuOpen
    } = useSelector(state => state.Portal)

    return (
        <>
        <div className="portal-header">
            <nav className="header-nav">
                <div className="header-inner">
                    <a className="logo" onClick={() => navigate('/')}><img src={logo}/></a>
                    <div className="menu">
                        <div className="inner">
                            <div className="menu-items">
                                <a className="item active">My Learning<span></span></a>
                                <a className="item">Watch Later<span></span></a>
                                <a className="item">My Playlists<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-right">
                    <div className="right-inner">
                        <div className="bar"></div>
                        <div className="account">
                            <div className="account-nav">
                                <div className="account-container">
                                    <button onClick={() => setShowMenu(!showMenu)} className="primary-link" aria-haspopup="true" aria-expanded="false">
                                        <img className=" nav-avatar fs-exclude " src="https://api-eu1.hubspot.com/userpreferences/v1/avatar/6734d7f6fffec1af2a7cc358eb14c116/100" />
                                        <span className="account-name fs-exclude ">Gilly Chris</span>
                                        <svg className="nav-icon arrow-down-icon" style={{maxHeight:"4px",maxWidth:"10px"}} data-name="Layer 1" viewBox="0 0 24 13" xmlns="http://www.w3.org/2000/svg">
                                            <path transform="translate(0)" d="m21.47 0.41l-9.47 9.02-9.47-9a1.5 1.5 0 1 0 -2.06 2.16l10.5 10a1.51 1.51 0 0 0 0.44 0.28 1.43 1.43 0 0 0 1 0 1.52 1.52 0 0 0 0.59 -0.26l10.5-10a1.5 1.5 0 1 0 -2.03 -2.2"></path>
                                        </svg>
                                    </button>
                                    <div className={`account-menu ${showMenu ? 'd-block' : 'd-none' }`}>
                                        <div className="user-prefs">
                                            <a>
                                                <img className="nav-avatar fs-exclude" src="https://api-eu1.hubspot.com/userpreferences/v1/avatar/6734d7f6fffec1af2a7cc358eb14c116/100" />
                                                <div className="user-info fs-exclude">
                                                    <div className="user-info-name">Gilly Chris</div>
                                                    <div className="user-info-email">chrisgillyfe@gmail.com</div>
                                                    <div className="user-info-preferences">Profile &amp; Preferences</div>
                                                </div>
                                        </a>
                                        </div>
                                        <ul className="account-extras">
                                            <li><a>Training and Services</a></li>
                                        </ul>
                                        <div className="account-nav-bottom">
                                            <div class="signout ">
                                                <a href="">Sign out</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <div className={`portal-mobile-header ${portalSideMenuOpen ? 'open' : ''}`}>
            <div className="container">
                <div className="content">
                    <ReactSVG src={logo} className="logo" onClick={() => navigate('/')}/>
                    <img className="menu" src={portalSideMenuOpen ? close : menu } onClick={() => dispatch(togglePortalSideMenu(!portalSideMenuOpen))}/>
                </div>
            </div>
        </div>
    </>
    )
}