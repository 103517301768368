import React from "react";
import './style.scss';
import serviceImg from 'src/Assets/Images/Services/HeadImg.svg';
import RadioCheck from 'src/Assets/Images/Home/RadioCheck.png';
import { services } from "src/Constant";
import mountainImg from "src/Assets/Images/Services/MountainImg.svg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ServicesPage() {
    const navigate = useNavigate()

    const meta_title = "Virtual Medical Assistants Services - Boost Your Practice | Honest Taskers"
    const meta_description = "Transform your medical practice with Honest Taskers' Virtual Medical Assistants. Expert, cost-effective support in administration, billing, and patient care. Experience the change today!"
    const meta_image = "https://honesttaskers.com/preview.png"

    function ServiceItem({item, index}) {

        return (
            <div className="service-item">
                <img src={item.img}/>
                <div className="content-info">
                    <div className="title"><span>{index < 9 ? '0' : ''}{index+1}</span> {item.title}</div>
                    <div className="desc"><span>Description</span> {item.message}</div>
                    {
                        item.benefits.length > 0 && <div className="list">
                            <div className="list-title">Benefits:</div>
                            {
                                item.benefits.map((lItem, lIndex) => (
                                    <div className="list-item" key={lIndex}>
                                        <img src={RadioCheck}/>
                                        <div>{lItem}</div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://www.honesttaskers.com/services"></link>
            </Helmet>
            <div className="services-page">
                <div className="top-section-wrapper">
                    <div className="head-section">
                        <div className="inner-bg">
                            <div className="about-intro">
                            <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="bread-crumb">
                                                <div className="item" onClick={() => navigate('/')}>Home</div>
                                                <div className="seperator">{'>'}</div>
                                                <div className="item active">Services</div>
                                            </div>
                                            <h2>Our Services at HonestTaskers</h2>
                                        </div>
                                    </div>
                                    <div className="bottom-section">
                                        <div className="row row-sm">
                                            <div className="col-lg-6">
                                                <div className="text-content">
                                                    <span>Overview:</span> At HonestTaskers, we go beyond conventional virtual assistance. We present you with multi-skilled virtual medical assistants, comprehensively trained and certified across a spectrum of medical administrative services. Our vision isn't just about offering a service; it's about providing a holistic solution, tailor-made for the healthcare industry, ensuring that with each assistant, you get a myriad of skills, ready to transform and uplift your practice.
                                                </div>
                                            </div>
                                            <div className="col-lg-6 ">
                                                <img src={serviceImg} className="about-img"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-content">
                    <div className="container">
                        <div className="row">
                            {
                                services.map((item, index) => (
                                    <div className="col-lg-6">
                                        <ServiceItem item={item} index={index}/>
                                    </div>
                                ))
                            }
                            <div className="col-lg-6">
                                <div className="taskers-edge">
                                    <div className="title">The HonestTaskers Edge:</div>
                                    <div> With every virtual medical assistant you engage with, you're unlocking a treasure trove of multifaceted skills, unified by our core values. These values spotlight humility, undying passion, perpetual growth, and a team spirit that's palpable, even in a virtual space.</div>
                                    <div className="img-row">
                                        <img src={mountainImg} />
                                        <div className="mt-3">Step into a World of Proficiency. Revel in Unparalleled Joy. Partner with HonestTaskers. Let us revolutionize your administrative sphere, granting you the freedom to channel your energies into core medical endeavors.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} 