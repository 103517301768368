import React, { useEffect, useState, useRef } from "react";
import './style.scss';
import share from 'src/Assets/Images/Profile/share.svg';
import picture from 'src/Assets/Images/Profile/picture.png';
import iconSchool from 'src/Assets/Images/Profile/icon-school.svg';
import iconUniv from 'src/Assets/Images/Profile/icon-univ.svg';
import iconMail from 'src/Assets/Images/Profile/icon-mail.svg';
import iconPhone from 'src/Assets/Images/Profile/icon-phone.svg';
import iconLocation from 'src/Assets/Images/Profile/icon-location.svg';
import iconTwitter from 'src/Assets/Images/Profile/icon-twitter.png';
import iconLinkedin from 'src/Assets/Images/Profile/icon-linkedin.png';
// import iconDot from 'src/Assets/Images/Profile/profileDot.svg';
import { Achievement, CertificateTrainingCard, Education, Endorsement, ExperienceCard, LatestProjects, Project, SkillModal, Skills, Tools, VideoModal } from "src/Components";
import { useDispatch, useSelector } from "react-redux";
import { getVAProfileAction, requestInterviewAction, toggleInterviewModal, toggleSkillModal, toggleVideoModal } from "src/Redux/Actions";
import { useParams } from "react-router-dom";
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import _ from 'lodash';
import RequestInterviewModal from "./RequestInterviewModal";

export default function ProfilePage() {
    const dispatch = useDispatch()
    const [tabIndex, setTabIndex] = useState(0)
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
    const params = useParams()
    const largeScreenIframeRef = useRef(null);
    const mobileScreenIframeRef = useRef(null);
    const {
        showSkill,
        hoverSkill,
        va_profile,
        getVAProfile_loading
    } = useSelector(state => state.Profile)

    const {
        requestInterview_loading,
        showInterviewModal,
        showVideoModal
    } = useSelector(state => state.Home)

    useEffect(() => {
        showSpinner()
        dispatch(getVAProfileAction(params.id))
    }, [])

    useEffect(() => {
        if (!getVAProfile_loading) {
            hideSpinner()
            console.log("=== profile ===", va_profile)
        }
    }, [dispatch, getVAProfile_loading])
    // const va_profile = JSON.parse(sessionStorage.getItem('profile'))

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = "https://muse.ai/static/js/embed-player.min.js";
    //     script.async = true;    
    //     document.body.appendChild(script);
    
    //     return () => {
    //       document.body.removeChild(script);
    //     };
    //   }, []);

    const groupedSkills = (items) => {
        const group = Object.values(items.reduce((acc, item) => {
            acc[item.position.title] = (acc[item.position.title] || []).concat(item);
            return acc;
        }, {}));
        return group
    }

    // Request interview
    const requestInterview = (data) => {
        showSpinner()
        dispatch(requestInterviewAction(data))
    }

    useEffect(() => {
        if (!requestInterview_loading) {
            hideSpinner()
        }
    }, [dispatch, requestInterview_loading])

    useEffect(() => {
        const largeScreen = window.innerWidth >= 992
        if (largeScreen) {
            mobileScreenIframeRef.current = null
        } else {
            largeScreenIframeRef.current = null
        }
        setIsLargeScreen(largeScreen)
        console.log(`Video Iframe, large: ${largeScreenIframeRef.current} mobile: ${mobileScreenIframeRef.current} isLargeScreen: ${isLargeScreen} width: ${window.innerWidth}`)
    }, [window.innerWidth])

    const youtube_parser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    const IntroVideo = ({ video, iframeRef }) => {
        return (
            <>
            {
            video.includes('youtube.com') || video.includes('youtu.be') ? 
            <div className="video-container">
               <iframe className='video'
                    title={va_profile.user.first_name}
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    style={{ margin: "15px 0",width:"100%",height:"300px" }}
                    src={`https://youtube.com/embed/${youtube_parser(video)}?autoplay=0`}>
                </iframe>   
            </div> :  
                <div className="video-container">
                    <iframe src={video} 
                    ref={iframeRef}
                    style={{ margin: "15px 0",width:"100%",height:"300px" }}
                    frameBorder="0" 
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen></iframe>
                </div>
            }
            </>
        )
    }

    function UserCard() {
        return (
            <div className="user-card">
                <div className="picture">
                    {
                        !_.isEmpty(va_profile.user.picture) ? <img src={va_profile.user.picture} alt={va_profile.user.first_name}/> :
                        <span>{va_profile.user.first_name ? va_profile.user.first_name.charAt(0) : 'A'}{va_profile.user.last_name ? va_profile.user.last_name.charAt(0) : 'L'}</span>
                    }
                </div>
                {
                    (!_.isEmpty(va_profile.user.video) && isLargeScreen) && <IntroVideo video={va_profile.user.video} iframeRef={largeScreenIframeRef} />
                }
                <div className="details bottom-b">
                    <div className="name">{va_profile.user.honorific ? `${va_profile.user.honorific}. ` : ''}{va_profile.user.first_name} {va_profile.user.last_name ? va_profile.user.last_name.charAt(0) : ''}</div>
                    <div className="role mb-3">{va_profile.user.title}</div>
                    <div className="info-row">
                        <img src={iconSchool}/>
                        <div>{va_profile.user.qualification}</div>
                    </div>
                    <div className="info-row">
                        <img src={iconUniv}/>
                        <div>{va_profile.user.institution}</div>
                    </div>
                    {/* <div className="btn btn-orange mt-3 w-100" onClick={() => dispatch(toggleInterviewModal(true))}>Interview Me</div> */}
                </div>
                <div className="details bottom-b">
                    <div className="info-row">
                        <img src={iconMail}/>
                        <div>info@honesttakers.com</div>
                    </div>
                    <div className="info-row">
                        <img src={iconPhone}/>
                        <div>817 420-7608</div>
                    </div>
                    <div className="info-row">
                        <img src={iconLocation}/>
                        <div>1751 River Run Suite 200 Fort Worth, TX 76008</div>
                    </div>
                </div>
                <div className="details">
                    <div className="label">Social</div>
                    <div className="socials">
                        <img src={iconLinkedin} onClick={() => window.open("https://www.linkedin.com/in/honest-taskers-04607229a/", '_blank', 'noopener,noreferrer')} />
                        <img src={iconTwitter} onClick={() => window.open("https://twitter.com/HonestTaskers", '_blank', 'noopener,noreferrer')} />
                    </div>
                    {/* <div className="btn btn-orange mt-4 w-100" onClick={() => dispatch(toggleInterviewModal(true))}>Interview Me</div> */}
                </div>
            </div>
        )
    }

    function InfoSection() {

        return (
            <div className="info-card">
                {
                    va_profile.user.experience.length > 0 && (
                        <div className="info-section">
                            <div className="title">Experience</div>
                            {
                                va_profile.user.experience.map((item, index) => (
                                    <ExperienceCard key={index} item={item} />
                                ))
                            }
                        </div>
                    )
                }
                {
                    va_profile.user.certificates.length > 0 && (
                        <div className="info-section">
                            <div className="title">Certificate & Training</div>
                            {
                               va_profile.user.certificates.map((item, index) => (
                                    <CertificateTrainingCard key={index} item={item}/>
                                ))
                            }
                        </div>
                    )
                }
                {
                    va_profile.user.skills.length > 0 && (
                        <div className="info-section">
                            <div className="title">Skills</div>
                            <div className="skills-rapper">
                                {
                                    groupedSkills(va_profile.user.skills).map((items, index) => (
                                        <Skills key={index} items={items} index={index} onShowSkill={(element) => dispatch(toggleSkillModal(true, element))}/>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    va_profile.user.education.length > 0 && (
                        <div className="info-section">
                            <div className="title">Education</div>
                            <Education items={va_profile.user.education}/>
                        </div>
                    )
                }
                {
                    va_profile.user.endorsements.length > 0 && (
                        <div className="info-section">
                            <div className="title">Endorsement</div>
                            {
                                va_profile.user.endorsements.map((item, index) => (
                                    <Endorsement key={index} item={item} />
                                ))
                            }
                        </div>
                    )
                }
                {
                    va_profile.user.tools.length > 0 && (
                        <div className="info-section">
                            <div className="title">Tools</div>
                            <Tools items={va_profile.user.tools}/>
                        </div>
                    )
                }
                {
                    va_profile.user.projects.length > 0 && (
                        <div className="info-section">
                            <div className="title">Latest Projects</div>
                            <LatestProjects items={va_profile.user.projects}/>
                        </div>
                    )
                }
                {
                    va_profile.user.achievements.length > 0 && (
                        <div className="info-section">
                            <div className="title">Achievement</div>
                            {
                               va_profile.user.achievements.map((item, index) => (
                                    <Achievement key={index} item={item}/>
                                ))
                            }
                        </div>
                    )
                }
                {/* <div className="btn btn-orange mt-4" onClick={() => dispatch(toggleInterviewModal(true))}>Interview Me</div> */}
                <SkillModal show={showSkill} item={hoverSkill} onHide={() => dispatch(toggleSkillModal(false, null))}/>
            </div>
        )
    }

    function MobileContactsTab() {
        return (
            <>
                <div className="details bottom-b">
                    <div className="info-row">
                        <img src={iconSchool}/>
                        <div>{va_profile.user.qualification}</div>
                    </div>
                    <div className="info-row">
                        <img src={iconUniv}/>
                        <div>{va_profile.user.institution}</div>
                    </div>
                    {/* <div className="btn btn-orange mt-3 w-100" onClick={() => dispatch(toggleInterviewModal(true))}>Interview Me</div> */}
                </div>
                <div className="details bottom-b">
                    <div className="info-row">
                        <img src={iconMail}/>
                        <div>info@honesttakers.com</div>
                    </div>
                    <div className="info-row">
                        <img src={iconPhone}/>
                        <div>817 420-7608</div>
                    </div>
                    <div className="info-row">
                        <img src={iconLocation}/>
                        <div>1751 River Run Suite 200 Fort Worth, TX 76008</div>
                    </div>
                </div>
                <div className="details">
                    <div className="label">Social</div>
                    <div className="socials">
                        <img src={iconLinkedin} onClick={() => window.open("https://www.linkedin.com/in/honest-taskers-04607229a/", '_blank', 'noopener,noreferrer')} />
                        <img src={iconTwitter} onClick={() => window.open("https://twitter.com/HonestTaskers", '_blank', 'noopener,noreferrer')} />
                    </div>
                    {/* <div className="btn btn-orange mt-4 w-100" onClick={() => dispatch(toggleInterviewModal(true))}>Interview Me</div> */}
                </div>
            </>
        )
    }

    async function copyTextToClipboard() {
        try {
            await navigator.clipboard.writeText(window.location.href);
            alert('Profile link copied!')
        } catch (err) {}
    }

    function ProfileLayout() {
        return (
            <div className="profile-page">
                <div className="container">
                    <div className="profile-wrapper main-view">
                    <div className="top-section">
                            <div className="b-crumb">
                                <div className="active">Home</div>
                                <div className="angle active">{' > '}</div>
                                <div className="">{va_profile.user.honorific ? `${va_profile.user.honorific}. ` : ''}{va_profile.user.first_name} {va_profile.user.last_name ? va_profile.user.last_name.charAt(0) : ''}</div>
                            </div>
                            <img src={share} onClick={() => copyTextToClipboard()}/>
                    </div>
                    <div className="row mt-4">
                            <div className="col-lg-3">
                                <UserCard />
                            </div>
                            <div className="col-lg-9">
                            <InfoSection />
                            </div>
                    </div>
                    </div>
                    <div className="profile-wrapper mobile-view">
                        <div className="top-section">
                            <div className="b-crumb">
                                <div className="active">Home</div>
                                <div className="angle active">{' > '}</div>
                                <div className="">{va_profile.user.honorific ? `${va_profile.user.honorific}. ` : ''}{va_profile.user.first_name}</div>
                            </div>
                        </div>
                        <div className="user-card">
                            <div className="picture">
                                {
                                    !_.isEmpty(va_profile.user.picture) ? 
                                    (<>
                                        <img src={va_profile.user.picture} alt={va_profile.user.first_name} className="image"/>
                                        <img src={share} className="share" onClick={() => copyTextToClipboard()}/>
                                    </>) :
                                    <span>{va_profile.user.first_name ? va_profile.user.first_name.charAt(0) : 'A'}{va_profile.user.last_name ? va_profile.user.last_name.charAt(0) : 'L'}</span>
                                }
                            </div>
                            <div className="details">
                                <div className="name text-center">{va_profile.user.honorific ? `${va_profile.user.honorific}. ` : ''}{va_profile.user.first_name} {va_profile.user.last_name ? va_profile.user.last_name.charAt(0) : ''}</div>
                                <div className="role mb-3 text-center">{va_profile.user.title}</div>
                            </div>
                            {
                                (!_.isEmpty(va_profile.user.video) && !isLargeScreen) &&  <IntroVideo video={va_profile.user.video} iframeRef={mobileScreenIframeRef} />
                            }
                            {/* mobile tab */}
                            <div className="mobile-tab">
                                <div className={`tab-item ${tabIndex === 0 ? 'active': ''}`} onClick={() => setTabIndex(0)}>contacts</div>
                                <div className={`tab-item ${tabIndex === 1 ? 'active': ''}`} onClick={() => setTabIndex(1)}>experience</div>
                            </div>
                            { tabIndex === 0 && <MobileContactsTab /> }
                            { tabIndex === 1 && <InfoSection /> }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
           { va_profile && <ProfileLayout /> }
           { va_profile && <RequestInterviewModal 
                    show={showInterviewModal} 
                    va={va_profile} 
                    onHide={() => dispatch(toggleInterviewModal(false))}
                    onSubmit={(data) => requestInterview(data)}
                />}
            { va_profile && <VideoModal show={showVideoModal} onHide={() => dispatch(toggleVideoModal(false))}/>}
        </>
    )
}