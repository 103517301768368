import React from "react";
import './style.scss';
import storyImg from 'src/Assets/Images/Home/OurStory.svg';
import { useNavigate } from "react-router-dom";

export default function VMedicalBillingIntroduction({title, message}) {
    const navigate = useNavigate()

    return (
        <div className="intro">
            <div className="container">
                <div className="row content">
                    <div className="col-lg-6 left-content">
                        <div className="img-wrapper">
                            <img src={storyImg}/>
                        </div>
                    </div>
                    <div className="col-lg-6 right-content">
                        <h2>{title}</h2>
                        {/* <div className="title">THE GENESIS:</div> */}
                        <p>{message}</p>
                        {/* <div className="btn btn-outline-primary mt-3" onClick={() => navigate('/story')}>READ MORE</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}