import React from "react";
import { Modal } from "react-bootstrap";
import './style.scss';

export default function SkillModal({show, onHide, item}) {

    return (
        <Modal className="skill-modal" show={show} onHide={onHide}>
            { item && <Modal.Header>
                    <div>{item.title}</div>
                    <div className="close" onClick={onHide}>Close</div>
                </Modal.Header>}
            { item && <Modal.Body dangerouslySetInnerHTML={{ __html: item.description }}></Modal.Body>}
        </Modal>
    )
}