import React, { useEffect, useRef } from "react";
import './style.scss';
import visionImg from 'src/Assets/Images/Vision/HeaderImg.svg';
import { CoreValues, visionServiceList } from "src/Constant";
import RadioCheck from 'src/Assets/Images/Home/RadioCheck.png';
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function VisionPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const valuesRef = useRef(null)

    useEffect(() => {
        if (location.hash === '#values') {
            valuesRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [location.hash])

    const meta_title = "Vision at Honest Taskers: Delivery Joy Through Service"
    const meta_description = "Discover Honest Taskers' vision: Delivering joy in healthcare services. Striving to be the best workplace for virtual medical assistants, we enhance medical practices with superior virtual support and efficiency."
    const meta_image = "https://honesttaskers.com/preview.png"

    function ServiceItem({item, index}) {
        return (
            <div className="service-item">
                <div className="img-wrapper">
                    <img src={item.img}/>
                </div>
                <div className="content-info">
                    <div className="title"><span>{index < 9 ? '0' : ''}{index+1}</span> {item.title}</div>
                    <div className="desc">{item.message}</div>
                    {
                        item.benefits.length > 0 && <div className="list">
                            <div className="list-title">Benefits:</div>
                            {
                                item.benefits.map((lItem, lIndex) => (
                                    <div className="list-item" key={lIndex}>
                                        <img src={RadioCheck}/>
                                        <div>{lItem}</div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }

    function PolicyItem({item, index}) {

        return (
            <div className="policy-item">
                <div className="img-wrapper">
                    <img src={item.img}/>
                </div>
                <div className="info-content">
                    <div className="title"><span>{index < 9 ? '0' : ''}{index+1}</span> {item.title}</div>
                    <div>{item.message}</div>
                    {
                        item.list.length > 0 && <ul>
                            {
                                item.list.map((lItem, lIndex) => (
                                    <li key={lIndex}>{lItem}</li>
                                ))
                            }
                        </ul>
                    }
                </div>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://www.honesttaskers.com/vision"></link>
            </Helmet>
            <div className="vision-page">
                <div className="top-section-wrapper">
                    <div className="head-section">
                        <div className="inner-bg">
                            <div className="about-intro">
                            <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="bread-crumb">
                                                <div className="item" onClick={() => navigate('/')}>Home</div>
                                                <div className="seperator">{'>'}</div>
                                                <div className="item active">Vision</div>
                                            </div>
                                            <h2>Vision</h2>
                                        </div>
                                    </div>
                                    <div className="bottom-section">
                                        <div className="row row-sm">
                                            <div className="col-lg-7">
                                                <div className="text-content">Be the best place for virtual medical assistants to work and deliver joy through services"</div>
                                            </div>
                                            <div className="col-lg-5 right-section">
                                                <img src={visionImg} className="about-img"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vision-service">
                    <div className="container">
                        <div className="row">
                            {
                                visionServiceList.map((item, index) => (
                                    <div className="col-lg-6" key={index}>
                                        <ServiceItem item={item} index={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="core-values" ref={valuesRef}>
                    <div className="container">
                        <h2>Core Values that Illuminate our Path:</h2>
                        <div className="row">
                            {
                                CoreValues.map((item, index) => (
                                    <div className="col-md-6" key={index}>
                                        <PolicyItem item={item} index={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}