import React, { useState } from "react";
import './style.scss';
import skillIMg from 'src/Assets/Images/Profile/skill-img.svg'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import { ArrowLeftOne, ArrowRightOne } from "src/Constant";
import { useDispatch } from "react-redux";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export default function Skills({index, onShowSkill, items}) {
    
    function SkillItem({item}) {

        return (
            <a className="item" 
                data-tooltip-id="my-tooltip"
                data-tooltip-content={item.description.replace(/<[^>]+>|&nbsp;/g, " ")}
                data-tooltip-place="bottom" style={{ background: "#E7F4E8"}} onClick={() => onShowSkill(item)}>
                <div className="name" style={{ color: "#288267"}}>{item.title}</div>
            </a>
        )
    }

    return (
        <div className="skill-item">
            <div className="row">
                <div className="col-lg-3 skill">
                    { items.length > 0 ? <img src={items[0].position.icon}/> : '' }
                    <div className="">{items.length > 0 ? items[0].position.title : ''}</div>
                </div>
                <Tooltip id="my-tooltip" />
                <div className="col-lg-9 skill-list">
                        <Media
                            queries={{
                                lg: "(min-width: 1655px)",
                                md: "(min-width: 1360px) and (max-width: 1654px)",
                                sm: "(min-width: 1032px) and (max-width: 1359px)",
                                normal: "(max-width: 1031px)",
                            }}
                        >
                            {(matches) => (
                                <OwlCarousel 
                                    key={index}
                                    className="owl-theme"
                                    stagePadding={
                                        matches.lg ? 20 : matches.md ? 0 : matches.sm ? 0 : 0
                                    }
                                    margin={
                                        matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                                    }
                                    items={matches.lg ? 3 : matches.md ? 2 : matches.sm ? 2 : 1}
                                    dots={false}
                                    nav
                                    navText={[ArrowLeftOne, ArrowRightOne]}
                                >
                                    {
                                        items.map((skill, index) => (
                                            <SkillItem item={skill} key={index} />
                                        ))
                                    }
                                </OwlCarousel>
                            )}
                        </Media>
                </div>
            </div>
        </div>
    )
}