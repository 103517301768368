import React from "react";
import blogUser from 'src/Assets/Images/Blog/BlogProfile.svg';
import arrowMore from 'src/Assets/Images/Blog/ArrowMore.svg';
import blogImg1 from 'src/Assets/Images/Blog/BlogImg1.png';
import blogImg2 from 'src/Assets/Images/Blog/BlogImg2.png';
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

export default function BlogFeatured({posts}) {
    const navigate = useNavigate()
    const latestPost = posts.length > 0 ? posts[0] : null

    const getUserName = (item) => {
        if (_.isEmpty(item.user.honorific)) return item.user.name
        return `${item.user.honorific}. ${item.user.name}`
    }

    const formatDate = (value) => {
        const date = new Date(value)
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }

    function RelatedPostItem({item}) {
        return (
            <div className="related-post" onClick={() => navigate(`/blog/${item.slug}`)}>
                <div className="img-wrapper">
                    <img src={item.image}/>
                </div>
                <div className="content">
                    <div className="blog-top-info">
                        <div className="type">{item.category.name}</div>
                        <div className="date">{formatDate(item.created_at.date)}</div>
                    </div>
                    <div className="title">{item.title}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="featured">
            <div className="container">
                {
                    latestPost && (
                        <div className="blog-user">
                            <div className="img-wrapper">
                                <img src={latestPost.user.picture}/>
                            </div>
                            <div className="info">
                                <div className="">{getUserName(latestPost)}</div>
                                <div className="role">{latestPost.user.title}</div>
                            </div>
                        </div>
                    )
                }
                <div className="row">
                    <div className="col-lg-8 left-section">
                        {
                            latestPost && (
                                <div className="story-blog">
                                    <div className="img-wrapper">
                                        <img src={latestPost.image} className="story-img"/>
                                    </div>
                                    <div className="story-blog-info">
                                        <div className="blog-top-info">
                                            <div className="type">{latestPost.category.name}</div>
                                            <div className="date">{formatDate(latestPost.created_at.date)}</div>
                                        </div>
                                        <div className="blog-title">{latestPost.title}</div>
                                        <div className="content"></div>
                                        <div className="read-more" onClick={() => navigate(`/blog/${latestPost.slug}`)}>
                                            <div>Read</div>
                                            <img src={arrowMore}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className="col-lg-4 right-section">
                       {
                        posts.length > 1 && (
                            <div className="side-blog" onClick={() => navigate(`/blog/${posts[1].slug}`)}>
                                <div className="img-wrapper">
                                    <img src={posts[1].user.picture}/>
                                </div>
                                <div className="blog-top-info">
                                    <div className="type">{posts[1].category.name}</div>
                                    <div className="date">{formatDate(posts[1].created_at.date)}</div>
                                </div>
                                <div className="title-sm">{posts[1].title}</div>
                            </div>
                        )
                       }
                        <div className="related-lists">
                            {
                               posts.length > 2 && <RelatedPostItem item={posts[2]}/>
                            }
                            {
                               posts.length > 3 && <RelatedPostItem item={posts[3]}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}