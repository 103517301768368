import { 
    TOGGLE_SKILL_MODAL,
    GET_VA_PROFILE,
    GET_VA_PROFILE_SUCCESS,
    GET_VA_PROFILE_FAIL
 } from "../Type"


const INITIAL = {
    showSkill: false,
    hoverSkill: null,
    // profile
    va_profile: null,
    getVAProfile_loading: false
}

export default (state = INITIAL, action) => {
    switch(action.type) {
        case TOGGLE_SKILL_MODAL: {
            const { show, skill } = action.data
            return {
                ...state,
                showSkill: show,
                hoverSkill: skill
            }
        }
        case GET_VA_PROFILE: {
            return  {
                ...state,
                getVAProfile_loading: true
            }
        }
        case GET_VA_PROFILE_SUCCESS: {
            return {
                ...state,
                getVAProfile_loading: false,
                va_profile: action.data
            }
        }
        case GET_VA_PROFILE_FAIL: {
            return {
                ...state,
                getVAProfile_loading: false
            }
        }
        default: 
            return state
    }
}