import React from "react";
import './style.scss';
import RadioCheck from 'src/Assets/Images/Home/RadioCheck.png';
import { StoriesList } from "src/Constant";
import { useNavigate } from "react-router-dom";
import storyImg from 'src/Assets/Images/Story/OurStoryHImg.svg';
import { Helmet } from "react-helmet";

export default function StoryPage() {
    const navigate = useNavigate()

    function StoryItem({item, className}) {

        return (
            <div className={`story-item ${className}`}>
                <div className="img-wrapper">
                    <img src={item.img}/>
                </div>
                <div className="story-message">
                    <div className="title">{item.title}</div>
                    <div>{item.message}</div>
                    {
                        item.list.length > 0 && (
                            <div className="list">
                                {
                                    item.list.map((lItem, index) => (
                                        <div className="list-item" key={index}>
                                            <img src={RadioCheck}/>
                                            <div>{lItem}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    const getClassPadding = (index) => {
        const pattern = index % 3
        if (pattern === 0) return ''
        return pattern === 1 ? 'pad-1' : 'pad-2'
    }

    const meta_title = "The Origin of Honest Taskers: Our Inspiring Founding Story"
    const meta_description = "Explore the founding story of Honest Taskers. Learn how our passion for quality healthcare led to innovative remote staffing solutions in virtual assistance, scribing, and more. Our journey exemplifies dedication to excellence and client service."
    const meta_image = "https://honesttaskers.com/preview.png"

    return (
        <>
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://www.honesttaskers.com/story"></link>
            </Helmet>
            <div className="story-page">
                <div className="story-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="bread-crumb">
                                    <div className="item" onClick={() => navigate('/')}>Home</div>
                                    <div className="seperator">{'>'}</div>
                                    <div className="item active">Our Story</div>
                                </div>
                                <h2>Our Story</h2>
                            </div>
                            <div className="col-md-6 bread-img">
                                <img src={storyImg}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="story-content">
                    <div className="container">
                        <div className="content">
                            {
                                StoriesList.map((item, index) => (
                                    <StoryItem item={item} key={index} className={getClassPadding(index)}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}