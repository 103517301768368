import { useDispatch } from 'react-redux';
import './style.scss';
import { toggleCalendlyModal } from "src/Redux/Actions";

export default function MobileActionButton() {
    const dispatch = useDispatch();

    return (
        <div className='wrapper'>
            <div className="call-btn" onClick={() => dispatch(toggleCalendlyModal(true))}>BOOK DISCOVERY CALL</div>
        </div>
    )
}