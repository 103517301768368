import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import { WhyUsList } from "src/Constant";
import { useNavigate } from "react-router-dom";
import whyHImg from 'src/Assets/Images/Home/WhyHImg.svg';
import { Helmet } from "react-helmet";

export default function WhyUsPage() {
    const navigate = useNavigate()

    const getColor = (index) => {
        const pattern = index % 3
        if (pattern === 0) return 'green';
        return pattern === 1 ? 'orange' : 'red'
    }

    function SliderCard({item, index}) {

        return (
            <div className={`b-card ${getColor(index)}`} key={index}>
                <div className="title">{item.title}</div>
                <div className="message">{item.message}</div>
                <div className="num-bg"></div>
                <div className="num">{index < 9 ? `0${index+1}` : `${index+1}`}</div>
            </div>
        )
    }

    return (
        <div className="why-us-page">
            <Helmet>
                <link rel="canonical" href="https://www.honesttaskers.com/why-us"></link>
            </Helmet>
            <div className="wus-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="bread-crumb">
                                <div className="item" onClick={() => navigate('/')}>Home</div>
                                <div className="seperator">{'>'}</div>
                                <div className="item active">Why Us</div>
                            </div>
                            <h2>Why Us</h2>
                        </div>
                        <div className="col-md-6 bread-img">
                            <img src={whyHImg}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container slider-container">
                <div className="wus-slider">
                    <Media
                        queries={{
                            lg: "(min-width: 1654px)",
                            md: "(min-width: 1200px) and (max-width: 1653px)",
                            sm: "(min-width: 768px) and (max-width: 1199px)",
                            normal: "(max-width: 767px)",
                        }}
                        >
                            {(matches) => (
                                <OwlCarousel 
                                    className="owl-theme"
                                    stagePadding={
                                        matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 15
                                    }
                                    margin={
                                        matches.lg ? 240 : matches.md ? 33 : matches.sm ? 45 : 20
                                    }
                                    items={matches.lg ? 5 : matches.md ? 3 : matches.sm ? 2 : 1}
                                    dots={false}
                                    loop
                                    autoplay
                                    rewind
                                    autoplaySpeed={500}
                                    autoplayHoverPause
                                    autoplayTimeout={2000}
                                >
                                    {
                                        WhyUsList.map((item, index) => (
                                            <SliderCard item={item} index={index} key={index}/>
                                        ))
                                    }
                                </OwlCarousel>
                            )}
                        </Media>
                </div>
                <div className="slider-mobile">
                    <div className="row">
                        {
                            WhyUsList.map((item, index) => (
                                <div className="col-12" key={index}>
                                    <SliderCard item={item} index={index}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}