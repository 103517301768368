import React, { useEffect, useState } from "react";
import './style.scss';
import connectImg from 'src/Assets/Images/VmrHome/ConnectImg.svg';
import { hideSpinner, showSpinner } from "src/Utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { submitContactAction, submitReceptionContactAction } from "src/Redux/Actions";
import { toast } from "react-toastify";

export default function Connect({}) {
    const dispatch = useDispatch()
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    const [company, setCompany] = useState('');

    const {
        submitContact_loading,
        submitContact_success
    } = useSelector(state => state.Contact)

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const formIsValid = name !== '' && email !== '' && content != ''

    const submitForm = () => {
        if (!formIsValid) {
            toast("Please enter your information", "error")
            return
        } 
        if (!email.match(validRegex))  {
            toast("Please, enter a valid email.", "error")
            return
        }
        showSpinner()
        const formdata = new FormData();
        formdata.append('name', name);
        formdata.append('email', email);
        formdata.append('phone', '');
        formdata.append('content', content);
        formdata.append('company', company);
        dispatch(submitReceptionContactAction(formdata));
    }

    useEffect(() => {
        if (!submitContact_loading) {
            hideSpinner()
        }
    }, [dispatch, submitContact_loading, submitContact_success])

    return (
        <div className="connect">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 left-section">
                        <h1>Ready to transform</h1>
                        <h3>your patient's first point of contact experience?</h3>
                        <h3 className="primary">Let's take the next step together.</h3>
                        <div className="form-content mt-4">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" className="form-control" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>E-mail</label>
                                <input type="text" className="form-control" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Company</label>
                                <input type="text" className="form-control" placeholder="Enter company name" value={company} onChange={(e) => setCompany(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label>Message</label>
                                <textarea className="form-control" rows={5} placeholder="Enter your message" value={content} onChange={e => setContent(e.target.value)}></textarea>
                            </div>
                            <div className="btn btn-orange btn-lg w-100 mt-4" onClick={() => submitForm()}>Connect Now</div>
                        </div>
                    </div>
                    <div className="col-md-6 right-section">
                        <img src={connectImg}/>
                    </div>
                </div>
            </div>
        </div>
    )
}