import React from "react";
import { useDispatch } from "react-redux";
import { Benefits, FAQs, Footer2, GetStarted2, Pricing, TVAssistantHomeIntro, VmHowItWorks } from "src/Components";
import WhyChooseUs from "src/Components/Home/VmrComponents/WhyChooseUs";
import { getFAQStructuralData, getImageStructuralData, getPricingPlans } from "src/Constant";
import { toggleCalendlyModal } from "src/Redux/Actions";
import { Helmet } from "react-helmet";


export default function TVAssistantHomepage() {
    const dispatch = useDispatch()

    const beneifts = [
        {
            title: "Seamless Virtual Interactions:",
            message: "Ensure every patient interaction online is smooth and free from technical hiccups.",
            color: "green"
        },
        {
            title: "Time-Efficient:",
            message: "With assistance in handling scheduling, reminders, and follow-ups, reduce wait times and no-shows.",
            color: "green"
        },
        {
            title: "Enhanced Patient Experience:",
            message: "Provide a more personalized touch, making patients feel valued and cared for.",
            color: "green"
        },
        {
            title: "Cost-Effective:",
            message: "Reduce overhead costs associated with in-person visits.",
            color: "green"
        },
        {
            title: "Flexibility & Scalability:",
            message: "Adapt to varying patient loads easily, scaling up or down based on demand.",
            color: "green"
        }
    ]

    const duties = [
        {
            title: "Scheduling & Appointments:",
            message: "Managing patient appointments, ensuring efficient time slots for both patients and healthcare providers."
        },
        {
            title: "Technical Support:",
            message: "Assisting patients with platform-related queries and ensuring smooth virtual visits."
        },
        {
            title: "Follow-ups & Reminders:",
            message: "Ensuring patients are reminded of upcoming appointments, leading to reduced no-shows."
        },
        {
            title: "Documentation & Reporting: ",
            message: "Compiling patient interactions and providing reports as necessary."
        },
        {
            title: "Billing & Payment Assistance:",
            message: "Helping patients with telehealth service billing queries."
        }
    ]

    const whyChooseList = [
        {
            title: "Highly Trained:",
            message: "Our assistants undergo specialized training tailored to telehealth service nuances."
        },
        {
            title: "Tech-Savvy:",
            message: "Familiarity with leading telehealth platforms ensures smooth patient interactions."
        },
        {
            title: "HIPAA Compliant:",
            message: "We adhere strictly to health data protection standards, safeguarding patient information."
        },
        {
            title: "24/7 Support:",
            message: "Our assistants are available round the clock to ensure global time zones are catered for."
        },
        {
            title: "Consistent Quality:",
            message: "With continuous training and quality checks, expect the best service at all times."
        }
    ]

    const faqs = [
        {
            question: "Are your telehealth assistants familiar with various telehealth platforms?",
            answer: "Absolutely! Our assistants are trained on a range of leading telehealth platforms to ensure versatility."
        },
        {
            question: "How do you maintain patient confidentiality during virtual visits?",
            answer: "We strictly adhere to HIPAA guidelines and use encrypted communications, ensuring patient data remains confidential."
        },
        {
            question: "Can an assistant handle patients from different time zones?",
            answer: "Yes, our assistants are available 24/7, making it convenient to cater to patients from various time zones."
        },
        {
            question: "What if we experience a surge in patient appointments?",
            answer: "Our services are scalable. Should you experience a surge, we can quickly allocate additional resources to manage the increased load."
        }
    ]

    const meta_title = "Telehealth Medical Assistants | Save 60% Now - Honest Taskers"
    const meta_description = "Transform your telehealth services with Honest Taskers’ Medical Assistants. Save over 60% on costs while enhancing efficiency and patient connectivity. Contact us today!"
    const meta_image = "https://honesttaskers.com/vmr_image.png"

    return (
        <div className="tv-assistant-homepage">
            <Helmet>
                <meta name="title" content={meta_title} />
                <meta name="description" content={meta_description} />
                <meta property="type" content="website" />
                <meta property="url" content={window.location.href} />
                <meta property="image" content={meta_image} />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemprop="name" content={meta_title} />
                <meta itemprop="description" content={meta_description} />
                <meta itemprop="image" content={meta_image} />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={meta_title} />
                <meta property="og:description" content={meta_description} />
                <meta property="og:image" content={meta_image} />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta_title} />
                <meta name="twitter:description" content={meta_description} />
                <meta name="twitter:image" content={meta_image} />
                <link rel="canonical" href="https://honesttaskers.com/telehealth-medical-assistant"></link>
                <script type="application/ld+json">{JSON.stringify(getImageStructuralData(meta_image, meta_title))}</script>
                <script type="application/ld+json">{JSON.stringify(getFAQStructuralData(faqs))}</script>
            </Helmet>
            <TVAssistantHomeIntro onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <Benefits subTitle={"of Telehealth Virtual Assistant"} benefits={beneifts}/>
            <VmHowItWorks howItWorkList={duties} title={"Duties and Responsibilities:"}/>
            <WhyChooseUs subTitle={"HonestTaskers' Telehealth Assistants?"} whyChoose={whyChooseList}/>
            <FAQs faqs={faqs}/>
            <Pricing hasColor={true} plans={getPricingPlans('contact')}/>
            <GetStarted2 title={"Take Your Telehealth Services to New Heights."}
                btnText="Engage with a Telehealth Expert Now!"
                onGetStarted={() => dispatch(toggleCalendlyModal(true))}/>
            <Footer2 />
        </div>
    )
}