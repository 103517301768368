import React from "react";
import './style.scss';

export default function CourseListing() {

    function CourseItem({item}) {
        return (
            <div className="course-item">
                <div className="img-wrapper">
                    <img src="https://images.unsplash.com/photo-1719937206642-ca0cd57198cc?q=80&w=3348&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="course name"/>
                    <div className="type">Lesson</div>
                    <div className="duration">1 hour</div>
                </div>
                <div className="title">Introduction to data science and technology</div>
                <p>Bootstrap’s dropdowns, on the other hand, are designed to be generic and applicable to a variety of situations and markup structures. For instance, it is possible to create dropdowns that contain additional inputs and form controls</p>
            </div>
        )
    }

    return (
        <div className="course-listing">
            <div className="list-section">
                <div className="title-flex">
                    <h2>Courses in progress</h2>
                    <a className="">See all <span className="fa fa-angle-right ms-2"></span></a>
                </div>
                <div className="course-items">
                    <div className="row">
                        {
                            Array(3).fill(true).map((item, index) => (
                                <div className="col-lg-4 col-md-6">
                                    <CourseItem key={index} item={item}/>
                                </div>
                            ))
                        }
                    </div>
                    <div className="btn btn-primary mt-3">See all <span className="fa fa-angle-right text-white ms-2"></span></div>
                </div>
            </div>
            <div className="list-section">
                <div className="title-flex">
                    <h2>Recommended Courses</h2>
                    <a className="">See all <span className="fa fa-angle-right ms-2"></span></a>
                </div>
                <div className="course-items">
                    <div className="row">
                        {
                            Array(3).fill(true).map((item, index) => (
                                <div className="col-lg-4 col-md-6">
                                    <CourseItem key={index} item={item}/>
                                </div>
                            ))
                        }
                    </div>
                    <div className="btn btn-primary mt-3">See all <span className="fa fa-angle-right text-white ms-2"></span></div>
                </div>
            </div>
        </div>
    )
}