import React from "react";
import './style.scss';
import docImg from 'src/Assets/Images/Home/DocImg.png';

export default function HomeIntro({onGetStarted}) {

    return (
        <div className="home-intro-wrapper">
           <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-7 left-section">
                                    <h1>Multipurpose Virtual<br></br><span>Medical Assistant</span></h1>
                                    <p>Trained to Support Practices with <span>Scribing</span>, <span>Administrative</span> Tasks, <span>Billing</span>, 
                                        and Marketing <span>Streamlining</span> Operations, Boosting Profitability, and <span>Saving</span> You Time.</p>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Get Started Now</div>
                                </div>
                                <div className="right-sec col-lg-5">
                                    <div className="c-wrapper">
                                        <img src={docImg} className="doc-img"/>
                                        <p className="quote">
                                        " Ever since partnering with Honesttaskers, our clinic has been running smoother than ever. Their 
                                        Virtual Medical Assistants are a game-changer in patient care and practice management "
                                        </p>
                                        <div className="author">Dr. Mathew Mcallister</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="intro-img-wrapper"></div>
            </div>
        </div>
    )
}