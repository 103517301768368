import React from "react";
import './style.scss';
import arrowMore from 'src/Assets/Images/Blog/ArrowMore.svg';
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

export default function ArticleItem({item}) {
    const navigate = useNavigate()
    const getUserName = () => {
        if (_.isEmpty(item.user.honorific)) return item.user.name
        return `${item.user.honorific}. ${item.user.name}`
    }

    const formatDate = (value) => {
        const date = new Date(value)
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    }

    return (
        <div className="article-item">
            <div className="img-wrapper">
                <img src={item.image} alt={item.image_alt}/>
            </div>
            <div className="article-content">
                <div className="top-info">
                    <div className="type">{item.category.name}</div>
                    <div className="date">{formatDate(item.created_at.date)}</div>
                </div>
                <div className="title" dangerouslySetInnerHTML={{ __html: item.title }}></div>
                <div className="blog-user">
                    <div className="img-wrapper">
                        <img src={item.user.picture}/>
                    </div>
                    <div className="info">
                        <div className="">{getUserName()}</div>
                        <div className="role">{item.user.title}</div>
                    </div>
                </div>
                <div className="read-more" onClick={() => navigate(`/blog/${item.slug}`)}>
                    <div>Read</div>
                    <img src={arrowMore}/>
                </div>
            </div>
        </div>
    )
}