import React, { useState } from "react";
import './style.scss';
import picture from 'src/Assets/Images/Profile/picture.png';
import iconLocation from 'src/Assets/Images/Profile/icon-location-2.svg';
import { monthShortNames } from "src/Constant";
import readmore from 'src/Assets/Images/Profile/readmore.svg'

export default function Endorsement({item}) {
    const [open, setOpen] = useState(false)

    const getDate = (item) => {
        const date = new Date(item.date)
        return `${monthShortNames[date.getMonth()]} ${date.getDate()}`
    }

    return (
        <div className="endorsement">
            <div className="left-section">
                <div className="img-wrapper">
                    { (item.author && item.author.picture) ? <img src={item.author.picture}/> : `${item.author.first_name ? item.author.first_name.charAt(0) : 'A'}${item.author.last_name ? item.author.last_name.charAt(0) : 'L'}`}
                </div>
                <div className="name">{item.author.name}</div>
                <div className="role">{item.author.title}</div>
                 { item.author.institution && <div className="inst">{item.author.institution}</div>}
            </div>
            <div className="right-section">
                <div className="info-row">
                    <div className="me-3">{item.date ? getDate(item) : ''}</div>
                    <div className=""><span><img src={iconLocation} className="me-2"/>{item.location}</span></div>
                </div>
                <p className={`message ${open ? 'open' : ''}`} dangerouslySetInnerHTML={{ __html: item.description }}></p>
                <div className="read-more" onClick={() => setOpen(!open)}>
                    <div className="">{open ? "Read less" : "Read more"}</div>
                    <img src={readmore} className={`${open ? 'open' : ''}`}/>
                </div>
            </div>
        </div>
    )
}