import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/Landing/DentalHomeIntro.png';

export default function VdrHomeIntro({onGetStarted}) {

    return (
        <div className="vdr-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container-fluid">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-5 left-section">
                                    <h1>Elevate Your Dental Practice</h1>
                                    <h2>with Our Expert Virtual Dental Receptionists!</h2>
                                    <p>Streamline appointments, enhance patient communications, and optimize office operations, all at a fraction of the cost.</p>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Get Started Today!</div>
                                </div>
                                <div className="right-sec col-lg-7">
                                    <img src={homeImg} alt="Virtual dental receptionist, remote dental receptionist and dental office receptionist "/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}