import { useRef } from 'react';
import './style.scss';

export default function VideoPreview({video}) {
    const iframeRef = useRef(null)

    return (
        <section className='video-preview container'>
            <div className='padding'>
                <h2>Watch Preview</h2>
                <div className="video-wrapper">
                    <iframe src={video.replace('vc', 'embed')} 
                        ref={iframeRef}
                        style={{ width:"576", height:"324" }}
                        frameBorder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen></iframe>
                </div>
            </div>
        </section>
    )
}