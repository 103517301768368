import { combineReducers } from 'redux';

import Home from './Home'
import Contact from './Contact';
import Profile from './Profile';
import Portal from './Portal';

export default combineReducers({
    Home,
    Contact,
    Profile,
    Portal
})