import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/VmrHome/HomeImg.svg';

export default function TTMedicalHomeIntro({onGetStarted}) {

    return (
        <div className="vmr-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-6 left-section">
                                    <h1>Telephone Triage Medical Assistant</h1>
                                    <h2>Telephone Triage Medical Assistant For Optimum Outcome</h2>
                                    <p>Rapid, accurate, and compassionate over-the-phone medical guidance.</p>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Connect with a Triage Expert Today</div>
                                </div>
                                <div className="right-sec col-lg-6">
                                    <img src={homeImg} alt="Telehealth Medical Assistants"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}