import { toast } from 'react-toastify';
import {
    GET_BLOG,
    GET_BLOGS,
    GET_BLOGS_FAIL,
    GET_BLOGS_SUCCESS,
    GET_BLOG_FAIL,
    GET_BLOG_SUCCESS,
    GET_TOPICS,
    GET_TOPICS_FAIL,
    GET_TOPICS_SUCCESS,
    GET_TOPIC_BLOGS,
    GET_TOPIC_BLOGS_FAIL,
    GET_TOPIC_BLOGS_SUCCESS,
    REQUEST_INTERVIEW,
    REQUEST_INTERVIEW_FAIL,
    REQUEST_INTERVIEW_SUCCESS
} from '../Type';

import { takeLatest, put } from 'redux-saga/effects';

import {
    getBlogs as getBlogsApi,
    getBlog as getBlogApi,
    getTopics as getTopicsApi,
    getTopicBlogs as getTopicBlogsApi,
    requestInterview as requestInterviewApi
} from 'src/Api/Home'

function* getBlogs() {
    try {
        const result = yield getBlogsApi();
        if (result && result.data) {
          yield put({ type: GET_BLOGS_SUCCESS, data: result.data });
        } else {
          yield put({ type: GET_BLOGS_FAIL, data: null });
        }
      } catch (err) {
        alert(err);
      }
}

export function* watchGetBlogs() {
    yield(takeLatest(GET_BLOGS, getBlogs))
}

function* getBlog(payload) {
  try {
      const result = yield getBlogApi(payload.blog_id);
      if (result && result.data) {
        yield put({ type: GET_BLOG_SUCCESS, data: result.data });
      } else {
        yield put({ type: GET_BLOG_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}

export function* watchGetBlog() {
  yield(takeLatest(GET_BLOG, getBlog))
}

function* getTopics() {
  try {
      const result = yield getTopicsApi();
      if (result && result.data) {
        yield put({ type: GET_TOPICS_SUCCESS, data: result.data });
      } else {
        yield put({ type: GET_TOPICS_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}

export function* watchGetTopics() {
  yield(takeLatest(GET_TOPICS, getTopics))
}

function* getTopicBlogs(payload) {
  try {
      const result = yield getTopicBlogsApi(payload.categoryId);
      if (result && result.data) {
        yield put({ type: GET_TOPIC_BLOGS_SUCCESS, data: result.data });
      } else {
        yield put({ type: GET_TOPIC_BLOGS_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}

export function* watchGetTopicBlogs() {
  yield(takeLatest(GET_TOPIC_BLOGS, getTopicBlogs))
}

function* requestInterview(payload) {
  try {
      const result = yield requestInterviewApi(payload.data);
      console.log(result)
      if (result && result.data) {
        toast("Interview request sent!")
        yield put({ type: REQUEST_INTERVIEW_SUCCESS, data: result.data });
      } else {
        yield put({ type: REQUEST_INTERVIEW_FAIL, data: null });
      }
    } catch (err) {
      alert(err);
    }
}

export function* watchRequestInterview() {
  yield(takeLatest(REQUEST_INTERVIEW, requestInterview))
}