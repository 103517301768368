import { takeLatest, put } from 'redux-saga/effects';

import {
    GET_VA_PROFILE,
    GET_VA_PROFILE_FAIL,
    GET_VA_PROFILE_SUCCESS,
} from '../Type';

import {
    getVAProfile as getVAProfileApi
} from 'src/Api/Home';


function* getVAProfile(payload) {
    try {
        const result = yield getVAProfileApi(payload.id)
        if (result && result.data) {
          sessionStorage.setItem("profile", JSON.stringify(result.data))
          yield put({ type: GET_VA_PROFILE_SUCCESS, data: result.data });
        } else {
          yield put({ type: GET_VA_PROFILE_FAIL, data: null });
        }
      } catch (err) {
        alert(err);
      }
  }
  
  export function* watchGetVAProfile() {
    yield(takeLatest(GET_VA_PROFILE, getVAProfile))
  }