import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/VmrHome/HomeImg.svg';

export default function VMedicalBillingHomeIntro({onGetStarted}) {

    return (
        <div className="vmr-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-6 left-section">
                                    <h1>Virtual Medical Billing Assistant</h1>
                                    <h2>Efficient, accurate, and compliant billing solutions at your fingertips.</h2>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Get Started!</div>
                                </div>
                                <div className="right-sec col-lg-6">
                                    <img src={homeImg} alt="Remote medical biller, virtual medical biller, virtual medical billing assistants"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}