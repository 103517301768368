import React from "react";
import './style.scss';
import connectImg from 'src/Assets/Images/VmrHome/ConnectImg.svg';

export default function GetStarted2({
    title = "Ready to transform", 
    title1, title2, 
    onGetStarted, 
    btnText = "Get Started Today!"}) {

    return (
        <div className="get-started-2">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 left-section">
                        <h1>{title}</h1>
                        { title1 && <h2>{title1}</h2>}
                        { title2 && <h2 className="primary">{title2}</h2>}
                        <div className="btn btn-orange btn-lg mt-4" onClick={() => onGetStarted()}>{btnText}</div>
                    </div>
                    <div className="col-md-6 right-section">
                        <img src={connectImg}/>
                    </div>
                </div>
            </div>
        </div>
    )
}