import React from "react";
import './style.scss';
import { faqs } from "src/Constant";
import { FaqAccordion } from "src/Components";
import { useNavigate } from "react-router-dom";
import faqHImg from 'src/Assets/Images/Home/FaqHImg.svg';
import { Helmet } from "react-helmet";

export default function FaqPage() {
    const navigate = useNavigate()

    const faqStructuralData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(item => {
          return {
          "@type": "Question",
          "name": item.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>"+ item.answer +"</p>"
            }
          }
        })
    }

    return (
        <div className="faq-page">
            <Helmet>
                <link rel="canonical" href="https://www.honesttaskers.com/faqs"></link>
                <script type="application/ld+json">{JSON.stringify(faqStructuralData)}</script>
            </Helmet>
            <div className="faq-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="bread-crumb">
                                <div className="item" onClick={() => navigate('/')}>Home</div>
                                <div className="seperator">{'>'}</div>
                                <div className="item active">Frequently Asked Questions</div>
                            </div>
                            <h2>Frequently Asked Questions</h2>
                        </div>
                        <div className="col-md-6 bread-img">
                            <img src={faqHImg}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                   <div className="row">
                        {
                            faqs.map((item, index) => (
                                <div className="col-lg-6" key={index}>
                                    <FaqAccordion item={item}/>
                                </div>
                            ))
                        }
                   </div>
                </div>
            </div>
        </div>
    )
}