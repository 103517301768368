import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import udemyLogo from 'src/Assets/Images/Profile/udemy.png';
import iconLink from 'src/Assets/Images/Profile/iconLink.svg'

export default function LatestProjects({items}) {

    function ProjectItem({item}) {

        return (
            <div className="project">
                {/* <div className="logo-wrapper"><img src={item.img}/></div> */}
                    <div className="content">
                        <div className="name">{item.title}</div>
                        <div className={`message mt-3`} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                        <div className="link" onClick={() => window.open(item.link, '_blank', 'noopener,noreferrer')}>
                            <img src={iconLink}/>
                            <div>{item.link}</div>
                        </div>
                </div>
            </div>
        )
    }

    return (
        <div className="latest-projects">
            <Media
                queries={{
                    lg: "(min-width: 1655px)",
                    md: "(min-width: 1360px) and (max-width: 1654px)",
                    sm: "(min-width: 1032px) and (max-width: 1359px)",
                    normal: "(max-width: 1031px)",
                }}
            >
                {(matches) => (
                    <OwlCarousel 
                        className="owl-theme"
                        stagePadding={
                            matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                        }
                        margin={
                            matches.lg ? 20 : matches.md ? 15 : matches.sm ? 15 : 10
                        }
                        items={matches.lg ? 2 : matches.md ? 2 : matches.sm ? 1 : 1}
                        dots={false}
                        nav={false}
                        loop
                        autoplay
                        rewind
                        autoplaySpeed={500}
                        autoplayHoverPause
                        autoplayTimeout={2000}
                    >
                        {
                            items.map((item, index) => (
                                <ProjectItem item={item} key={index} index={index}/>
                            ))
                        }
                    </OwlCarousel>
                )}
            </Media>
        </div>
    )
}