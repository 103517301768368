import React, { useState } from "react";
import './style.scss';
import readmore from 'src/Assets/Images/Profile/readmore.svg'

export default function CertificateTrainingCard({item}) {
    const [open, setOpen] = useState(false)
    
    return (
        <div className="cert-card">
            <div className="icon-wrapper">
                { item.company.logo ? <img src={item.company.logo} alt={''}/> : ''}
            </div>
                <div className="content">
                    <div className="top-info">
                        <div className="left">
                            <div className="role">{item.title ?? "Title"}</div>
                            <div className="comp-name">{item.company.name}</div>
                        </div>
                        <div className="date" onClick={() => window.open(item.url, '_blank', 'noopener,noreferrer')}>View Certificate</div>
                    </div>
                    <div className={`message mt-3 ${open ? 'open' : ''}`} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    <div className="read-more" onClick={() => setOpen(!open)}>
                        <div className="">{open ? "Read less" : "Read more"}</div>
                        <img src={readmore} className={`${open ? 'open' : ''}`}/>
                    </div>
            </div>
        </div>
    )
}