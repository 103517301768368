import React from "react";
import './style.scss';
import serviceImg1 from 'src/Assets/Images/Home/Service1.svg';
import serviceImg2 from 'src/Assets/Images/Home/Service2.svg';
import serviceImg3 from 'src/Assets/Images/Home/Service3.svg';
import serviceImg4 from 'src/Assets/Images/Home/Service4.svg';
import radioCheck from 'src/Assets/Images/Home/RadioCheck.png';

export default function ServicesProvide({onGetStarted}) {

    const ServiceInfo = [
        { 
            title: "SCRIBING", 
            sub_title: "Assist healthcare professionals in documenting patient interactions during consultations.",
            image: serviceImg1,
            tasks: [
                "Transcribing patient-doctor interactions in real-time.",
                "Organizing and updating electronic health records (EHR).",
                "Ensuring accuracy and completeness of all records."
            ]
        },
        {
            title: "ADMINISTRATIVE DUTIES",
            sub_title: "Streamline the day-to-day operations of the practice.",
            image: serviceImg2,
            tasks: [
                "Managing patient appointments and scheduling.",
                "Handling patient communication: reminders, follow-ups, and feedback.",
                "Assisting with credentialing and licensing tasks."
            ]
        },
        {
            title: "BILLING",
            sub_title: "Ensure accurate and timely billing, leading to consistent revenue flow.",
            image: serviceImg3,
            tasks: [
                "Preparing and submitting claims to insurance companies.",
                "Reviewing and managing accounts and outstanding balances.",
                "Handling patient billing queries and disputes."
            ]
        },
        {
            title: "MARKETING",
            sub_title: "Enhance the practice's visibility and attract more patients.",
            image: serviceImg4,
            tasks: [
                "Maintaining and updating the practice's website and social media profiles.",
                "Designing and distributing promotional materials digitally",
                "Planning and executing digital marketing campaigns"
            ]
        },
        {
            title: "MEDICAL CODING",
            sub_title: "Ensure accurate medical coding for optimal reimbursement and compliance with healthcare regulations.",
            image: serviceImg1,
            tasks: [
                "Analyzing patient records and assigning appropriate codes for diagnoses and procedures.",
                "Reviewing coding for accuracy and adherence to industry standards.",
                "Collaborating with healthcare providers to clarify diagnoses and procedures for accurate coding."
            ]
        },
        {
            title: "MEDICAL RECEPTIONIST",
            sub_title: "Provide exceptional front-line support to enhance patient experience and ensure efficient office operations.",
            image: serviceImg1,
            tasks: [
                "Answering and directing phone calls and emails from patients and healthcare providers.",
                "Scheduling and confirming appointments to optimize practice schedules.",
                "Managing patient records and ensuring confidentiality and accuracy in data entry.",
                "Conduct insurance verification and prior authorization"
            ]
        },
        {
            title: "PATIENT INTAKE COORDINATOR",
            sub_title: "Facilitate smooth and efficient patient intake processes, improving patient satisfaction and practice efficiency.",
            image: serviceImg1,
            tasks: [
                "Collecting and verifying patient information before appointments.",
                "Coordinating pre-visit paperwork, prior authorization, and insurance authorizations.",
                "Communicating with patients to ensure they are prepared for their visits."
            ]
        },
        {
            title: "REMOTE PATIENT MONITORING",
            sub_title: "Enhance patient care and outcomes by continuously tracking health metrics and promptly addressing any concerns.",
            image: serviceImg1,
            tasks: [
                "Monitoring patient data from remote devices and reporting any anomalies to healthcare providers.",
                "Communicating with patients to provide guidance and support based on their health data.",
                "Ensuring data is accurately recorded and integrated into patient records for comprehensive care management."
            ]
        },
        {
            title: "VIRTUAL MENTAL HEALTH ASSISTANT",
            sub_title: "Provide support to mental health professionals and enhance patient care through effective administrative and therapeutic assistance.",
            image: serviceImg1,
            tasks: [
                "Scheduling appointments and managing the calendar for mental health professionals.",
                "Assisting with patient intake and maintaining confidential patient records.",
                "Facilitating communication between patients and therapists, and providing resources and support as needed."
            ]
        }
    ];

    return (
        <div className="services-provide">
            <div className="container">
                <div className="content">
                    <h2>Services Provide</h2>
                    <p>By Our Multipurpose Virtual Medical Assistants:</p>
                    <div className="service-content">
                        <div className="row row-sm">
                            {
                                ServiceInfo.map((item, index) => (
                                    <div className="col-lg-6" key={index}>
                                        <div className="service-item">
                                            <div className="service-img"><img src={item.image}/></div>
                                            <div className="info">
                                                <div className="title">{item.title}</div>
                                                <div className="sub-title"><span>Purpose:</span> {item.sub_title}</div>
                                                <div className="task-list">
                                                    <div className="list-title">Tasks Include:</div>
                                                    <div className="list">
                                                        {
                                                            item.tasks.map(task => (
                                                                <div className="list-item" key={task}>
                                                                    <img src={radioCheck}/>
                                                                    <div className="text">{task}</div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="btn btn-outline-primary" onClick={() => onGetStarted()}>Get Started Now</div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}