import React from "react";
import './style.scss';
import dateIcon from 'src/Assets/Images/Policy/iconDate.svg';
import { TermsOfServiceList } from "src/Constant";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function TermsOfServicePage() {
    const navigate = useNavigate()

    function TermsItem({item, index}) {

        return (
            <div className="terms-item">
                <div className="num-wrapper">{index < 9 ? '0' : ''}{index+1}</div>
                <div className="info">
                    <div className="title">{item.title}</div>
                    <div>{item.message}</div>
                    {
                        item.list.length > 0 && (
                            <ul>
                                {
                                    item.list.map((lItem, lIndex) => (
                                        <li key={lIndex}>{lItem}</li>
                                    ))
                                }
                            </ul>
                        )
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="terms-page">
            <Helmet>
                <link rel="canonical" href="https://www.honesttaskers.com/terms-of-service"></link>
            </Helmet>
            <div className="terms-header">
                <div className="container">
                    <div className="bread-crumb">
                        <div className="item" onClick={() => navigate('/')}>Home</div>
                        <div className="seperator">{'>'}</div>
                        <div className="item active">Terms of Service for HonestTaskers</div>
                    </div>
                    <h2>Terms of Service for HonestTaskers</h2>
                    <div className="date">
                        <img src={dateIcon} />
                        <div>10.17.23</div>
                    </div>
                </div>
            </div>
            <div className="terms-content">
                <div className="container">
                    <div className="content">
                        <div className="row">
                            {
                                TermsOfServiceList.map((item, index) => (
                                    <div className="col-md-6">
                                        <TermsItem item={item} index={index}/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}