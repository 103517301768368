import { Api } from "./Api";

export function* getBlogs() {
    const jsonData = yield Api.get("/posts")
    return jsonData
}

export function* getTopicBlogs(categoryId) {
    const jsonData = yield Api.get(`/posts?category_id=${categoryId}`)
    return jsonData
}

export function* getBlog(id) {
    const jsonData = yield Api.get(`/posts/${id}`)
    return jsonData
}

export function* getTopics() {
    const jsonData = yield Api.get("/topics")
    return jsonData
}

export function* getVAProfile(id) {
    const jsonData = yield Api.get(`/VA/${id}/profile`)
    return jsonData
}

export function* requestInterview(data) {
    const jsonData = yield Api.formPost("/va/interview", data)
    return jsonData
}