import React from "react";
import './style.scss';
import storyImg from 'src/Assets/Images/Home/OurStory.svg';
import { useNavigate } from "react-router-dom";

export default function Introduction() {
    const navigate = useNavigate()

    return (
        <div className="intro">
            <div className="container">
                <div className="row content">
                    <div className="col-lg-6 left-content">
                        <div className="img-wrapper">
                            <img src={storyImg}/>
                        </div>
                    </div>
                    <div className="col-lg-6 right-content">
                        <h2>Intro To Remote MDS Coordinator</h2>
                        {/* <div className="title">THE GENESIS:</div> */}
                        <p>HonestTaskers understands the pivotal role MDS coordinators play in long-term care settings. As the bridge between patient assessments, care planning, and regulatory compliance, our remote MDS coordinators are meticulously trained to deliver exceptional value. Experience a blend of dedication, professionalism, and up-to-date industry knowledge, tailored for your facility’s unique needs.</p>
                        {/* <div className="btn btn-outline-primary mt-3" onClick={() => navigate('/story')}>READ MORE</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}