import './style.scss';

export default function CoursesPage() {

    return (
        <div className='courses'>
            <div className='container'>
                <h2>Free Training Courses for Virtual Medical Assitants</h2>
                <div className='landing-content'>
                    <div className="practice-item-component shadow-object ">
                        <img src="https://api.gradegetter.com/storage/mimstopics/9TzLXGI4wfg5z9BkOdJlFJToBczi1fGV5FElgkEB.png" alt="practice-logo" className="practice-logo" />
                        <div className="footer">
                            <div className="btn-2 practice-btn text-center active active-btn">Practice 480 Questions</div>
                        </div>
                    </div>
                    <div className="practice-item-component shadow-object ">
                        <img src="https://api.gradegetter.com/storage/mimstopics/9TzLXGI4wfg5z9BkOdJlFJToBczi1fGV5FElgkEB.png" alt="practice-logo" className="practice-logo" />
                        <div className="footer">
                            <div className="btn-2 practice-btn text-center active active-btn">Practice 480 Questions</div>
                        </div>
                    </div>
                    <div className="practice-item-component shadow-object ">
                        <img src="https://api.gradegetter.com/storage/mimstopics/9TzLXGI4wfg5z9BkOdJlFJToBczi1fGV5FElgkEB.png" alt="practice-logo" className="practice-logo" />
                        <div className="footer">
                            <div className="btn-2 practice-btn text-center active active-btn">Practice 480 Questions</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}