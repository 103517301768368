import './style.scss';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";

export default function CourseInstructors() {

    function TutorItem() {
        return (
            <div className='tutor'>
                <div className='info'>
                    <div className='profile'>
                        <img height="60" width="60" alt="Photo of Jacqueline Babb" src="https://hubspot-academy.s3.amazonaws.com/prod/content/professors/images/243-1586874709986.jpeg" />
                    </div>
                    <div className='name'>Jacqueline Babb</div>
                </div>
                <div className='summary'>
                    Jacqueline Babb is an Assistant Professor and Chair of Marketing at Aurora University. She teaches classes in digital marketing and integrated marketing communication at the undergraduate and graduate levels. Most recently, 
                    Jacqueline designed an innovative Masters in Digital Marketing and Design that will prepare students for a high-tech, data driven marketplace. Jacqueline is a graduate of the University of Illinois at Urbana-Champaign and Northwestern University; and a proud HubSpot Inbound…
                </div>
            </div>
        )
    }

    return (
        <section className='tutors'>
            <div className='container'>
                <div className='padding'>
                    <h2>Instructors</h2>
                    <div>
                        <div className='tutor-content'>
                            <Media
                                queries={{
                                    lg: "(min-width: 1655px)",
                                    md: "(min-width: 1360px) and (max-width: 1654px)",
                                    sm: "(min-width: 1032px) and (max-width: 1359px)",
                                    normal: "(max-width: 1031px)",
                                }}
                            >
                                {(matches) => (
                                    <OwlCarousel 
                                        className="owl-theme"
                                        stagePadding={
                                            matches.lg ? 0 : matches.md ? 0 : matches.sm ? 0 : 0
                                        }
                                        margin={
                                            matches.lg ? 80 : matches.md ? 20 : matches.sm ? 10 : 0
                                        }
                                        items={matches.lg ? 2 : matches.md ? 2 : matches.sm ? 1 : 1}
                                        dots
                                        loop
                                        autoplay
                                        rewind
                                        autoplaySpeed={1000}
                                        autoplayHoverPause
                                        autoplayTimeout={4000}
                                        nav={false}
                                    >
                                        {
                                            Array(10).fill(true).map((index, item) => (
                                                <TutorItem key={index}/>
                                            ))
                                        }
                                    </OwlCarousel>
                                )}
                            </Media>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}