import React from "react";
import './style.scss'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { togglePortalSideMenu } from "src/Redux/Actions";

export default function PortalMobileSideMenu({show}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const changePage = (page) => {
        dispatch(togglePortalSideMenu(false))
        navigate(page)
    }

    return (
        <div className={`p-mobile-menu-wrapper ${show ? '' : 'd-none'}`}>
            <div className="side-menu">
                <div className="content">
                    {/* <div className="close">
                        <img src={close} onClick={() => dispatch(toggleSideMenu(false))}/>
                    </div> */}
                    <nav className="nav-list">
                        <div className="menu-item" onClick={() => changePage('/about')}>About Us</div>
                        <div className="menu-item" onClick={() => changePage('/about')}>About Us</div>
                        <div className="menu-item" onClick={() => changePage('/about')}>About Us</div>
                    </nav>
                </div>
            </div>
        </div>
    )
}