import React from "react";
import './style.scss';

export default function Duties({duties, title, subTitle}) {

    function DutyItem({item, index}) {

        return (
            <div className="col-md-6">
                <div className="duty-item">
                    <img src={item.img}/>
                    <div className="title"><span>{index<9?'0':''}{index+1}</span> {item.title}</div>
                    <div>{item.message}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="duties">
            <div className="container">
                <h2>{title}</h2>
                <h3>{subTitle}</h3>
                <div className="row">
                    {
                        duties.map((item, index) => (
                            <DutyItem item={item} index={index} key={index}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}