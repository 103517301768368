import { Routes, Route} from 'react-router-dom';
import { Footer, Header } from '../Components';
import {
    HomePage,
    AboutUsPage,
    StoryPage,
    WhyUsPage,
    FaqPage,
    ContactUsPage,
    PrivacyPolicyPage,
    ServicesPage,
    TermsOfServicePage,
    VisionPage,
    BlogPage,
    BlogDetailPage,
    ProfilePage,
    BlogTopicPage,
    FulfillmentPolicy,
    CoursesPage,
    CourseDetailPage
} from 'src/Pages';
import { VmsHomepage } from 'src/Pages/VMSLanding';
import { VmrHomepage } from 'src/Pages/VMRLanding';
import VdrHomepage from 'src/Pages/VDRLanding';
import VMedicalBillingHomepage from 'src/Pages/VMedicalBillingLanding';
import VMedicalCoderHomepage from 'src/Pages/VMedicalCoder';
import VMentalHealthHomepage from 'src/Pages/VMentalHealth';
import RPMonitoringHomepage from 'src/Pages/RPMonitoringLanding';
import TVAssistantHomepage from 'src/Pages/TVAssistantLanding';
import TTMedicalHomepage from 'src/Pages/TTMedicalLanding';
import VPCareHomepage from 'src/Pages/VPCareLanding';
import RemoteMDSHomepage from 'src/Pages/RemoteMDSLanding';
import RCChatHomepage from 'src/Pages/RCChatLanding';
import VDAssistantHomepage from 'src/Pages/VDAssistantLanding';
import MPVAssistantLanding from 'src/Pages/MPVAssistantLanding';
import MobileActionButton from 'src/Components/MobileActionButton';


export default function LandingRoutes() {

    return (
        <div>
            <Header />
            <div className='main-content'>
                <Routes>
                    <Route exact path="/" element={<HomePage />}/>
                    <Route exact path='/about' element={<AboutUsPage />} />
                    <Route exact path='/story' element={<StoryPage />}/>
                    <Route exact path='/why-us' element={<WhyUsPage />} />
                    <Route exact path='/faqs' element={<FaqPage />} />
                    <Route exact path='/contact-us' element={<ContactUsPage />} />
                    <Route exact path='/privacy-policy' element={<PrivacyPolicyPage />} />
                    <Route exact path='/services' element={<ServicesPage />} />
                    <Route exact path='/terms-of-service' element={<TermsOfServicePage />} />
                    <Route exact path='/vision' element={<VisionPage />} />
                    <Route exact path='/blog' element={<BlogPage />} />
                    <Route exact path='/blog/:slug' element={<BlogDetailPage />} />
                    <Route exact path='/blog/:topic/:id' element={<BlogTopicPage />} />
                    <Route exact path='/virtual-medical-scribe' element={<VmsHomepage />} />
                    <Route exact path='/virtual-medical-receptionist' element={<VmrHomepage />} />
                    <Route exact path='/virtual-dental-receptionist' element={<VdrHomepage />} />
                    <Route exact path='/virtual-medical-billing' element={<VMedicalBillingHomepage />} />
                    <Route exact path='/virtual-medical-coder' element={<VMedicalCoderHomepage />} />
                    <Route exact path='/virtual-mental-health-assistant' element={<VMentalHealthHomepage />} />
                    <Route exact path='/remote-patient-monitoring-assistant' element={<RPMonitoringHomepage />} />
                    <Route exact path='/telehealth-medical-assistant' element={<TVAssistantHomepage />} />
                    <Route exact path='/telephone-triage-medical-assistant' element={<TTMedicalHomepage />} />
                    <Route exact path='/virtual-patient-care-coordinator' element={<VPCareHomepage />} />
                    <Route exact path='/remote-mds-coordinator' element={<RemoteMDSHomepage />} />
                    <Route exact path='/remote-clinical-chat-auditor' element={<RCChatHomepage />} />  
                    <Route exact path='/virtual-dental-assistant' element={<VDAssistantHomepage />} />
                    <Route exact path='/multi-purpose-virtual-medical-assistant' element={<MPVAssistantLanding />} />
                    <Route exact path='/profile/:id' element={<ProfilePage />} />
                    <Route exact path='/:name/:id/profile' element={<ProfilePage />} />
                    <Route exact path='/fulfillment-policy' element={<FulfillmentPolicy />} />
                    <Route exact path='/courses' element={<CoursesPage />} />
                    <Route exact path='/courses/:slug' element={<CourseDetailPage />} />
                </Routes>
            </div>
            <MobileActionButton />
            <Footer />
        </div>
    )
}