import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/VmsHome/HomeImg.svg';

export default function RPMonitoringHomeIntro({onGetStarted}) {

    return (
        <div className="vmr-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-6 left-section">
                                    <h1>Remote Patient Monitoring Assistant</h1>
                                    <h2>Empower Patient Care with Remote Patient Monitoring!</h2>
                                    <p>Expert assistance to monitor, manage, and motivate your patients remotely.</p>
                                    {/* <p>Streamline appointments, enhance patient communications, and optimize office operations, all at a fraction of the cost.</p> */}
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Hire a Remote Monitoring Expert</div>
                                </div>
                                <div className="right-sec col-lg-6">
                                    <img src={homeImg} alt="Remote Patient Monitoring"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}