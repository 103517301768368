import React, { useState } from "react";
import './style.scss';

export default function CourseProgress() {
    const [currentTab, setCurrentTab] = useState(0)

    function LessonItem({lesson}) {
        return (
            <div className="lesson-item">
                <div className="circle"></div>
                <div>
                    <div className="lesson-title">Introduction to AI for Marketers</div>
                    <div className="lesson-time">2 minutes</div>
                </div>
            </div>
        )
    }

    function ModuleBlock() {
        const [showLessons, setShowLessons] = useState(false)
        return (
            <div className={`module-block ${showLessons ? 'open' : ''}`}>
                <div className="d-flex a-s" onClick={() => setShowLessons(!showLessons)}>
                    <span className="fa fa-angle-right me-3 mt-1"></span>
                    <div>
                        <div className="title">Introduction to AI for Marketers</div>
                        <div className="info">1 Video | Total: 2 mins</div>
                    </div>
                </div>
                <div className={`lesson-wrapper ${showLessons ? '' : 'd-none'}`}>
                    {
                        Array(2).fill(true).map((item, index) => (
                            <LessonItem lesson={item} key={index}/>
                        ))
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="course-progress">
            <div className="top-header">
                <div className="title">AI for Marketers</div>
                <div className="module">Introduction to AI for Marketers</div>
            </div>
            <div className="progress-wrapper">
                <div className="progress-nav">
                    {
                        Array(3).fill(true).map((item, index) => (
                            <ModuleBlock key={index}/>
                        ))
                    }
                </div>
                <div className="lesson-content">
                    <div className="container">
                        <div className="video-wrapper">
                            <iframe src="https://www.youtube.com/embed/B_-5EngeZcI?si=wGCg3jVpv5VWxpwG" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            <div className="video-actions">
                                <div className="next-btn">Next</div>
                            </div>
                        </div>
                        <div className="lesson-tabs">
                            <div className={`tab-item ${currentTab == 0 ? 'active' : ''}`} onClick={() => setCurrentTab(0)}>About this lesson</div>
                            <div className={`tab-item ${currentTab == 1 ? 'active' : ''}`} onClick={() => setCurrentTab(1)}>Resources</div>
                        </div>
                        <div className="tab-contents">
                            <div className="lesson-overview">
                                <div className="title">Lesson overview</div>
                                <p>Ready to step up your marketing game? Learn how you can make the most of artificial intelligence (AI) to ramp up your marketing strategies. In this course, you'll learn how AI tools can supercharge your content creation. You’ll see how to use AI to get deeper customer insights and personalize your marketing like never before. Plus, you'll discover how AI can help you predict trends and make smarter marketing decisions. But it's not just about using AI‌ — it's about using it responsibly and ethically. We'll cover the ethical considerations of AI usage, ensuring you can confidently employ AI tools in the right way. This course is suitable for marketers at all levels of experience. Whether you're just starting with AI or looking to optimize your existing AI tools and strategies, this course will turbocharge your skills. By the end of this course, you'll be equipped with the knowledge and skills to effectively leverage artificial intelligence tools to transform your marketing strategies. You'll be able to optimize content creation, gain predictive insights, and critically evaluate AI tools to ensure ethical and responsible use. Get ready to take your marketing to the next level with AI.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}