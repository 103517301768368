import React from "react";
import './style.scss';
import storyImg from 'src/Assets/Images/Home/OurStory.svg';
import { useNavigate } from "react-router-dom";

export default function OurStory() {
    const navigate = useNavigate()

    return (
        <div className="our-story">
            <div className="container">
                <div className="row content">
                    <div className="col-lg-6 left-content">
                        <div className="img-wrapper">
                            <img src={storyImg}/>
                        </div>
                    </div>
                    <div className="col-lg-6 right-content">
                        <h2>Our Story & Why We Started HonestTaskers</h2>
                        <div className="title">THE GENESIS:</div>
                        <p>Meet Roland Omene, an educational entrepreneur with ventures like FrogTutoring and GradeGetter under his belt, and Dr. Zian Omene, the dedicated mind behind Shiloh Health, a unique integrative psychiatric practice. Together, they embarked on a journey that culminated in HonestTaskers.</p>
                        <div className="btn btn-outline-primary mt-3" onClick={() => navigate('/story')}>READ MORE</div>
                    </div>
                </div>
            </div>
        </div>
    )
}