import React from "react";
import './style.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Media from "react-media";
import { ArrowLeftOne, ArrowRightOne } from "src/Constant";
import udemyLogo from 'src/Assets/Images/Profile/udemy.png';

export default function Tools({items}) {


    function CardItem({item}) {

        return (
            <div className="item">
                { item.icon ? <img src={item.icon}/> : ''}
                <div className="info">
                    <div className="name">{item.name}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="tools">
            <Media
                queries={{
                    lg: "(min-width: 1655px)",
                    md: "(min-width: 1360px) and (max-width: 1654px)",
                    sm: "(min-width: 1032px) and (max-width: 1359px)",
                    normal: "(max-width: 1031px)",
                }}
            >
                {(matches) => (
                    <OwlCarousel 
                        className="owl-theme"
                        stagePadding={
                            matches.lg ? 20 : matches.md ? 10 : matches.sm ? 0 : 0
                        }
                        margin={
                            matches.lg ? 20 : matches.md ? 20 : matches.sm ? 10 : 0
                        }
                        items={matches.lg ? 3 : matches.md ? 2 : matches.sm ? 2 : 1}
                        dots={false}
                        nav
                        navText={[ArrowLeftOne, ArrowRightOne]}
                    >
                        {
                           items.map((item, index) => (
                                <CardItem key={index} item={item}/>
                            ))
                        }
                    </OwlCarousel>
                )}
            </Media>
        </div>
    )
}