import React, { useState } from "react";
import './style.scss';
import readmore from 'src/Assets/Images/Profile/readmore.svg'
import { monthShortNames } from "src/Constant";

export default function ExperienceCard({item}) {
    const [open, setOpen] = useState(false)

    const formatDate = (value) => {
        if(!value)return "Now"
        const date = new Date(value)
        return `${monthShortNames[date.getMonth()]} ${date.getFullYear()}`
    }

    return (
        <div className="exp-card">
            <div className="logo-wrapper">
                { item.company.logo ? <img src={item.company.logo} alt={''}/> : ''}
            </div>
            <div className="content">
                <div className="top-info">
                    <div className="left">
                        <div className="role">{item.title ?? 'Title'}</div>
                        <div className="comp-name">{item.company.name}</div>
                    </div>
                    <div className="date">{formatDate(item.start_date)} - {item.current ? "Now" : formatDate(item.end_date)}</div>
                </div>
                <div className={`message mt-3 ${open ? 'open' : ''}`} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                <div className="read-more" onClick={() => setOpen(!open)}>
                    <div className="">{open ? "Read less" : "Read more"}</div>
                    <img src={readmore} className={`${open ? 'open' : ''}`}/>
                </div>
            </div>
        </div>
    )
}