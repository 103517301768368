import { all, fork } from 'redux-saga/effects';

import {
    watchGetBlogs,
    watchGetBlog,
    watchGetTopics,
    watchGetTopicBlogs,
    watchRequestInterview
} from './Home';
import { watchSubmitContact, watchSubmitReceptionContact } from './Contact';
import { watchGetVAProfile } from './Profile';

export default function* rootSaga() {
    yield all([
      fork(watchGetBlogs),
      fork(watchGetBlog),
      fork(watchGetTopics),
      fork(watchSubmitContact),
      fork(watchSubmitReceptionContact),
      fork(watchGetVAProfile),
      fork(watchGetTopicBlogs),
      fork(watchRequestInterview)
    ]);
}