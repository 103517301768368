import React from "react";
import './style.scss';
import homeImg from 'src/Assets/Images/VmrHome/HomeImg.svg';

export default function TVAssistantHomeIntro({onGetStarted}) {

    return (
        <div className="vmr-home-intro-wrapper">
             <div className="intro-content">
                <div className="inner-bg">
                    <div className="container">
                        <div className="info-c">
                            <div className="row">
                                <div className="col-lg-6 left-section">
                                    <h1>Telehealth Virtual Assistant</h1>
                                    <h2>Elevate Your Practice With Telehealth Virtual Assistant</h2>
                                    <p>Streamline, support, and enhance virtual patient interactions every step of the way.</p>
                                    <div className="btn btn-orange btn-lg" onClick={() => onGetStarted()}>Hire Your Telehealth Assistant Today</div>
                                </div>
                                <div className="right-sec col-lg-6">
                                    <img src={homeImg} alt="Telehealth Medical Assistants"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}