import React from "react";
import { CatalogSection, CourseListing } from "src/Components";

export default function PortalHomePage() {

    return (
        <div className="portal-home">
           <div className="home-padding">
                <CatalogSection />
                <div className="home-content">
                    <div className="container">
                        <CourseListing />
                    </div>
                </div>
           </div>
        </div>
    )
}